import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const AssetsTable = ({ assets }) => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                Asset Name
              </Typography>
            </TableCell>
            <TableCell>
              <Typography variant="h6" component="div" sx={{ fontWeight: 'bold' }}>
                Type
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {assets.map((asset) => (
            <TableRow key={asset.id}>
              <TableCell>
                <Link to={`/asset/${asset.id}`} style={{ textDecoration: 'none', color: '#0077B6', fontWeight: 'bold' }}>
                  {asset.name}
                </Link>
              </TableCell>
              <TableCell>{asset.type}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AssetsTable;
