import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';

const Footer = () => {
  return (
    <Box sx={{ bgcolor: 'background.paper', py: 6, mt: 8 }}> {/* Added mt: 8 for margin-top */}
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Insight ISMS
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Your integrated solution for Information Security Management.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Services
            </Typography>
            <Link href="#" color="inherit" variant="body2" display="block" gutterBottom>
              Risk Assessments
            </Link>
            <Link href="#" color="inherit" variant="body2" display="block" gutterBottom>
              Mitigation Plans
            </Link>
            <Link href="#" color="inherit" variant="body2" display="block" gutterBottom>
              Compliance
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              About
            </Typography>
            <Link href="#" color="inherit" variant="body2" display="block" gutterBottom>
              Company
            </Link>
            <Link href="#" color="inherit" variant="body2" display="block" gutterBottom>
              Team
            </Link>
            <Link href="#" color="inherit" variant="body2" display="block" gutterBottom>
              Careers
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" gutterBottom>
              Contact
            </Typography>
            <Typography variant="body2" color="textSecondary" display="block" gutterBottom>
              Email: support@ezw.no
            </Typography>
          </Grid>
        </Grid>
        <Box mt={15}>
          <Typography variant="body2" color="textSecondary" align="center">
            © {new Date().getFullYear()} EZW AS. All rights reserved.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
