import React, { useEffect } from 'react';
import { Container, Box } from '@mui/material';
import { styled } from '@mui/system';
import BetaSignup from '../components/BetaSignup';
import Benefits from '../components/Benefits';
import Customers from '../components/Customers';
import RiskAssessmentProcess from '../components/RiskAssessmentProcess';
import { track } from '@vercel/analytics';

const WideContainer = styled(Container)(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    maxWidth: '1280px', // Adjust the value to your requirement
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

const LandingPage = () => {
  useEffect(() => {
    track('pageview', { path: window.location.pathname });
  }, []);

  return (
    <>
      <BetaSignup />
      <WideContainer>
        <Benefits />
      </WideContainer>
      <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', backgroundColor: '#0077B6', padding: '6rem 0 12rem' }}>
        <WideContainer>
          <Customers />
        </WideContainer>
      </Box>
      <WideContainer>
        <RiskAssessmentProcess />
      </WideContainer>
    </>
  );
};

export default LandingPage;
