import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';

const RiskDistributionChart = ({ riskDistribution }) => {
  const doughnutData = {
    labels: ['Low', 'Medium', 'High', 'Extreme'],
    datasets: [
      {
        data: [riskDistribution.low, riskDistribution.medium, riskDistribution.high, riskDistribution.extreme],
        backgroundColor: ['#388e3c', '#fbc02d', '#f57c00', '#d32f2f'],
        hoverBackgroundColor: ['#2e7d32', '#f9a825', '#ef6c00', '#c62828'],
      },
    ],
  };

  const doughnutOptions = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '50%',
  };

  return (
    <Box mt={4} sx={{ height: '300px' }}>
      <Typography variant="h5" component="h2" gutterBottom>
        Risk Distribution
      </Typography>
      <Doughnut data={doughnutData} options={doughnutOptions} />
    </Box>
  );
};

export default RiskDistributionChart;
