import React from 'react';
import { Line } from 'react-chartjs-2';
import { Box, Typography } from '@mui/material';
import { Chart, registerables } from 'chart.js';

Chart.register(...registerables);

const RiskOverTimeChart = ({ lineChartData }) => {
  const chartData = lineChartData && lineChartData.length > 0 ? {
    labels: lineChartData.map(data => data.date),
    datasets: [
      {
        label: 'Low',
        data: lineChartData.map(data => data.low),
        borderColor: '#388e3c',
        backgroundColor: 'rgba(56, 142, 60, 0.5)',
        fill: true,
        stack: 'stack1',
      },
      {
        label: 'Medium',
        data: lineChartData.map(data => data.medium),
        borderColor: '#fbc02d',
        backgroundColor: 'rgba(251, 192, 45, 0.5)',
        fill: true,
        stack: 'stack1',
      },
      {
        label: 'High',
        data: lineChartData.map(data => data.high),
        borderColor: '#f57c00',
        backgroundColor: 'rgba(245, 124, 0, 0.5)',
        fill: true,
        stack: 'stack1',
      },
      {
        label: 'Extreme',
        data: lineChartData.map(data => data.extreme),
        borderColor: '#d32f2f',
        backgroundColor: 'rgba(211, 47, 47, 0.5)',
        fill: true,
        stack: 'stack1',
      },
    ],
  } : {};

  const chartOptions = {
    responsive: true,
    scales: {
      x: {
        stacked: true,
        type: 'category',
      },
      y: {
        stacked: true,
      },
    },
  };

  return (
    <Box mt={4} width="100%">
      <Typography variant="h5" component="h2" gutterBottom>
        Risk Over Time
      </Typography>
      {lineChartData && lineChartData.length > 0 ? (
        <Line data={chartData} options={chartOptions} />
      ) : (
        <Typography variant="body1" color="textSecondary">
          No data available
        </Typography>
      )}
    </Box>
  );
};

export default RiskOverTimeChart;
