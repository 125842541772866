import React, { createContext, useState, useMemo, useContext, useEffect } from 'react';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';

export const ThemeContext = createContext(); // Exporting ThemeContext

export const useTheme = () => useContext(ThemeContext);

const lightTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#0077B6',
      light: '#56a6e9',
      dark: '#004d85',
    },
    secondary: {
      main: '#00A8E8',
    },
    text: {
      primary: '#333333',
      secondary: '#5c5c5c',
    },
    background: {
      default: '#F0F0F0',
      paper: '#ffffff',
    },
  },
  typography: {
    h1: { color: '#333333' },
    h2: { color: '#333333' },
    h3: { color: '#333333' },
    body1: { color: '#5c5c5c' },
    body2: { color: '#5c5c5c' },
  },
});

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2B5C84',
      light: '#5d89b4',
      dark: '#c0ebff',
    },
    secondary: {
      main: '#4A90E2',
      dark: '#003a80',
    },
    text: {
      primary: '#FFFFFF',
      secondary: '#e0e0e0',
    },
    background: {
      default: '#0F1C2D',
      paper: '#1f2b3d',
    },
  },
  typography: {
    h1: { color: '#FFFFFF' },
    h2: { color: '#FFFFFF' },
    h3: { color: '#FFFFFF' },
    body1: { color: '#e0e0e0' },
    body2: { color: '#e0e0e0' },
  },
});

const setCSSVariables = (theme) => {
  const root = document.documentElement;
  const themeMode = theme.palette.mode;

  if (themeMode === 'light') {
    root.setAttribute('data-theme', 'light');
  } else {
    root.setAttribute('data-theme', 'dark');
  }

  root.style.setProperty('--primary-100', theme.palette.primary.main);
  root.style.setProperty('--primary-200', theme.palette.primary.light);
  root.style.setProperty('--primary-300', theme.palette.primary.dark);
  root.style.setProperty('--accent-100', theme.palette.secondary.main);
  root.style.setProperty('--accent-200', theme.palette.secondary.dark);
  root.style.setProperty('--text-100', theme.palette.text.primary);
  root.style.setProperty('--text-200', theme.palette.text.secondary);
  root.style.setProperty('--bg-100', theme.palette.background.default);
  root.style.setProperty('--bg-200', theme.palette.background.paper);
};

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(() => {
    const savedTheme = localStorage.getItem('theme');
    return savedTheme === 'dark' ? darkTheme : lightTheme;
  });

  useEffect(() => {
    localStorage.setItem('theme', theme.palette.mode);
    setCSSVariables(theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prevTheme) =>
      prevTheme.palette.mode === 'light' ? darkTheme : lightTheme
    );
  };

  const value = useMemo(() => ({ theme, toggleTheme }), [theme]);

  return (
    <ThemeContext.Provider value={value}>
      <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>
    </ThemeContext.Provider>
  );
};
