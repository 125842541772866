// src/pages/Home.js
import React, { useContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import supabase from '../supabaseClient';
import { Container, Box, Typography, Button, Grid, Paper, List, ListItem, ListItemText } from '@mui/material';
import MitigationsTable from '../components/MitigationsTable';
import RiskDistributionChart from '../components/RiskDistributionChart';
import RiskOverTimeChart from '../components/RiskOverTimeChart';
import AssetsTable from '../components/AssetsTable';

const Home = () => {
  const { user } = useContext(AuthContext);
  const [assets, setAssets] = useState([]);
  const [riskAssessments, setRiskAssessments] = useState([]);
  const [riskOverTime, setRiskOverTime] = useState([]);
  const [riskDistribution, setRiskDistribution] = useState({
    low: 0,
    medium: 0,
    high: 0,
    extreme: 0,
  });
  const navigate = useNavigate();

  useEffect(() => {
    const fetchAssets = async () => {
      if (!user) return;

      const { data: assetsData, error } = await supabase
        .from('assets')
        .select('*')
        .eq('owner_id', user.id);

      if (error) {
        console.error('Error fetching assets:', error);
      } else {
        console.log('Assets:', assetsData); // Debug log
        setAssets(assetsData);
      }
    };

    fetchAssets();
  }, [user]);

  useEffect(() => {
    const fetchRiskAssessments = async () => {
      if (!user) return;

      const { data: riskAssessmentsData, error } = await supabase
        .from('risk_assessments')
        .select('*')
        .eq('user_id', user.id);

      if (error) {
        console.error('Error fetching risk assessments:', error);
      } else {
        console.log('Risk Assessments:', riskAssessmentsData); // Debug log
        setRiskAssessments(riskAssessmentsData);
      }
    };

    fetchRiskAssessments();
  }, [user]);

  useEffect(() => {
    const fetchRiskOverTime = async () => {
      const { data: riskOverTimeData, error } = await supabase
        .from('risk_over_time')
        .select('*');

      if (error) {
        console.error('Error fetching risk over time data:', error);
      } else {
        setRiskOverTime(riskOverTimeData);
      }
    };

    const fetchRiskDistribution = async () => {
      const { data: risksData, error: risksError } = await supabase
        .from('risks')
        .select('*');

      if (risksError) {
        console.error('Error fetching risks:', risksError);
        return;
      }

      const { data: vulnerabilitiesData, error: vulnerabilitiesError } = await supabase
        .from('vulnerabilities')
        .select('*');

      const { data: consequencesData, error: consequencesError } = await supabase
        .from('consequences')
        .select('*');

      if (vulnerabilitiesError || consequencesError) {
        console.error('Error fetching vulnerabilities or consequences:', vulnerabilitiesError || consequencesError);
        return;
      }

      const userAssets = assets.map(asset => asset.id);
      const userRisks = risksData.filter(risk => userAssets.includes(risk.asset_id));

      const riskScores = userRisks.map(risk => {
        const vulnerability = vulnerabilitiesData.find(v => v.id === risk.vulnerability_id);
        const consequence = consequencesData.find(c => c.id === risk.consequence_id);
        return vulnerability && consequence ? vulnerability.score * consequence.score : 0;
      });

      const distribution = { low: 0, medium: 0, high: 0, extreme: 0 };
      riskScores.forEach(score => {
        if (score <= 6) distribution.low += 1;
        else if (score <= 12) distribution.medium += 1;
        else if (score <= 18) distribution.high += 1;
        else distribution.extreme += 1;
      });

      setRiskDistribution(distribution);
    };

    fetchRiskOverTime();
    fetchRiskDistribution();
  }, [assets]);

  const handleCreateRiskAssessment = () => {
    navigate('/create-risk-assessment');
  };

  const handleViewRiskAssessment = (id) => {
    navigate(`/risk-assessment/${id}`);
  };

  const processDataForLineChart = (data) => {
    if (!data) return [];

    const categorizedData = data.reduce((acc, curr) => {
      const date = new Date(curr.timestamp).toLocaleDateString();
      const score = curr.risk_score;

      if (!acc[date]) {
        acc[date] = { low: 0, medium: 0, high: 0, extreme: 0, risks: {} };
      }

      if (!acc[date].risks[curr.risk_id]) {
        if (score <= 6) acc[date].low += 1;
        else if (score <= 12) acc[date].medium += 1;
        else if (score <= 18) acc[date].high += 1;
        else if (score <= 25) acc[date].extreme += 1;

        acc[date].risks[curr.risk_id] = score;
      }

      return acc;
    }, {});

    const dates = Object.keys(categorizedData).sort((a, b) => new Date(a) - new Date(b));
    const formattedData = dates.map((date, index) => {
      if (index > 0) {
        const prevDate = dates[index - 1];
        const prevRisks = categorizedData[prevDate].risks;

        Object.keys(prevRisks).forEach(riskId => {
          if (!categorizedData[date].risks[riskId]) {
            const score = prevRisks[riskId];
            if (score <= 6) categorizedData[date].low += 1;
            else if (score <= 12) categorizedData[date].medium += 1;
            else if (score <= 18) categorizedData[date].high += 1;
            else if (score <= 25) categorizedData[date].extreme += 1;

            categorizedData[date].risks[riskId] = score;
          }
        });
      }
      return {
        date,
        low: categorizedData[date].low,
        medium: categorizedData[date].medium,
        high: categorizedData[date].high,
        extreme: categorizedData[date].extreme,
      };
    });

    return formattedData;
  };

  const lineChartData = processDataForLineChart(riskOverTime);

  return (
    <Container maxWidth="lg">
      <Box display="flex" flexDirection="column" alignItems="left" justifyContent="center" textAlign="Left" mt={12}>
        <Typography variant="h4" component="h1" gutterBottom>
          Welcome to Home
        </Typography>
        {user && (
          <>
            <Typography variant="h6" component="p" gutterBottom>
              Logged in as: {user.email}
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} md={4}>
                <RiskDistributionChart riskDistribution={riskDistribution} />
              </Grid>
              <Grid item xs={12} md={8}>
                <RiskOverTimeChart lineChartData={lineChartData} />
              </Grid>
            </Grid>
            <Box mt={4}>
              <MitigationsTable user={user} />
            </Box>
            
            <Typography variant="h6" gutterBottom mt={4}>
              Your Assets
            </Typography>
            <AssetsTable assets={assets} />
            <Typography variant="h6" gutterBottom mt={4}>
              Your Risk Assessments
            </Typography>
            <List>
              {riskAssessments.map((assessment) => (
                <Paper key={assessment.id} sx={{ mb: 2, p: 2 }}>
                  <ListItem>
                    <ListItemText primary={assessment.name} secondary={assessment.description} sx={{ mr: 4 }} />
                    <Button variant="outlined" color="secondary" onClick={() => handleViewRiskAssessment(assessment.id)}>
                      View Risk Assessment
                    </Button>
                  </ListItem>
                </Paper>
              ))}
            </List>
            <Button variant="contained" color="primary" onClick={handleCreateRiskAssessment} sx={{ mb: 3 }}>
              Create Risk Assessment
            </Button>
          </>
        )}
      </Box>
    </Container>
  );
};

export default Home;
