// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header';
import Home from './pages/Home';
import About from './pages/About';
import Pricing from './pages/Pricing';
import Login from './pages/Login';
import MyAssets from './pages/MyAssets';
import Admin from './pages/Admin';
import UserProfile from './pages/UserProfile';
import { AuthProvider } from './contexts/AuthContext';
import CreateRiskAssessment from './pages/CreateRiskAssessment';
import RiskAssessmentDetails from './pages/RiskAssessmentDetails';
import Landing from './pages/Landing';
import Register from './pages/Register';
import OrganizationOptions from './pages/OrganizationOptions';
import CreateOrganization from './pages/CreateOrganization';
import LandingPage from './pages/LandingPage';
import { ThemeProvider } from './contexts/ThemeContext'; // Updated import
import RegisterForBeta from './pages/RegisterForBeta'; // Added this import
import Footer from './components/Footer';
import { Box } from '@mui/material';
import AssetDetails from './pages/AssetDetails';
import { Analytics } from "@vercel/analytics/react";
import Iso27001Process from './pages/Iso27001Process'; // Add this import

function App() {
  const organizationId = 'your-organization-id'; // Replace this with actual logic to get the organization ID

  return (
    <ThemeProvider>
      <AuthProvider>
        <Router>
          <Header />
          <Analytics />
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route path="/welcome" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/my-assets" element={<MyAssets />} />
            <Route path="/asset/:id" element={<AssetDetails />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/user/:id" element={<UserProfile />} />
            <Route path="/create-risk-assessment" element={<CreateRiskAssessment />} />
            <Route path="/risk-assessment/:id" element={<RiskAssessmentDetails />} />
            <Route path="/landing" element={<Landing />} />
            <Route path="/organization-options" element={<OrganizationOptions />} />
            <Route path="/create-organization" element={<CreateOrganization />} />
            <Route path="/register-for-beta" element={<RegisterForBeta />} />
            <Route
              path="/iso27001-process"
              element={<Iso27001Process organizationId={organizationId} />}
            />
          </Routes>
          <Box mt={8} /> {/* Add spacing before the footer */}
          <Footer /> {/* Add this line */}
        </Router>
      </AuthProvider>
    </ThemeProvider>
  );
}

export default App;
