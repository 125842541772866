import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { styled } from '@mui/system';

const BenefitCard = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2),
  borderLeft: `4px solid ${theme.palette.primary.main}`,
  backgroundColor: 'transparent',
  borderRadius: theme.shape.borderRadius,
}));

const Benefits = () => {
  return (
    <Box mt={8} mb={8}>
      <Grid container spacing={4} alignItems="center">
        <Grid item xs={12} md={6}>
          <Box sx={{ width: '100%', borderRadius: '8px', overflow: 'hidden', position: 'relative', paddingBottom: '56.25%', height: 0 }}>
            <iframe
              width="100%"
              height="100%"
              src="https://www.youtube.com/embed/v4Pgig6DxcA"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
              title="Insight ISMS Demo"
            ></iframe>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%" textAlign="left">
            <Typography variant="h4" component="h2" gutterBottom>
              Discover the benefits of a risk management system <span style={{ color: '#0077B6', fontWeight: 'bold' }}>designed to save you time</span>
            </Typography>
            <Grid container spacing={2} mt={4}>
              <Grid item xs={12} sm={6}>
                <BenefitCard>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    Easy to use interface
                  </Typography>
                </BenefitCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BenefitCard>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    AI enhanced
                  </Typography>
                </BenefitCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BenefitCard>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    Mitigation tracking
                  </Typography>
                </BenefitCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <BenefitCard>
                  <Typography variant="h6" component="h3" fontWeight="bold">
                    Stakeholder control
                  </Typography>
                </BenefitCard>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Benefits;
