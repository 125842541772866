import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Stepper,
  Step,
  StepLabel,
  FormControlLabel,
  Switch,
  Typography,
  TextField,
} from '@mui/material';
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import supabase from '../supabaseClient';
import axios from 'axios';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const ExecutiveSummary = ({ risks, vulnerabilities, consequences, summary, setSummary, id, setMessage, aiEnabled }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [useStepper, setUseStepper] = useState(true);
  const [isEditing, setIsEditing] = useState(false);
  const [editSummary, setEditSummary] = useState(summary);
  const [loading, setLoading] = useState(false);
  const [textFieldHeight, setTextFieldHeight] = useState('auto');
  const textFieldRef = useRef(null);
  const steps = ['Original Risk', 'Potential Risk'];

  useEffect(() => {
    if (textFieldRef.current) {
      setTextFieldHeight(textFieldRef.current.scrollHeight + 'px');
    }
  }, [editSummary]);

  const getRiskColor = (riskScore) => {
    if (riskScore < 4) return 'green'; // Low risk
    if (riskScore < 9) return 'yellow'; // Medium risk
    if (riskScore < 16) return 'orange'; // High risk
    return 'red'; // Extreme risk
  };

  const getRiskData = (type) => {
    const groupedData = {};

    risks.forEach((risk) => {
      const vulnerability = vulnerabilities.find((v) => v.id === risk.vulnerability_id);
      const consequence = consequences.find((c) => c.id === risk.consequence_id);

      if (!vulnerability || !consequence) return;

      let x, y, riskScore;

      switch (type) {
        case 'original':
          x = vulnerability.original_score;
          y = consequence.original_score;
          riskScore = x * y;
          break;
        case 'potential':
          x = vulnerability.potential_score;
          y = consequence.potential_score;
          riskScore = x * y;
          break;
        default:
          x = y = riskScore = 0;
      }

      const key = `${x}-${y}`;
      if (!groupedData[key]) {
        groupedData[key] = {
          x,
          y,
          riskScore,
          count: 0,
          fill: getRiskColor(riskScore),
          titles: [],
        };
      }

      groupedData[key].count += 1;
      groupedData[key].titles.push(risk.name);
    });

    return Object.values(groupedData);
  };

  const renderScatterPlot = (type) => {
    const data = getRiskData(type);
    const ticks = [0, 1, 2, 3, 4, 5];

    return (
      <ResponsiveContainer width="100%" height={500}>
        <ScatterChart margin={{ top: 40, right: 40, bottom: 60, left: 60 }}>
          <CartesianGrid />
          <XAxis
            type="number"
            dataKey="x"
            domain={[0, 5]}
            ticks={ticks}
            tickFormatter={(tick) => {
              switch (tick) {
                case 0:
                  return 'None';
                case 1:
                  return 'Very Low';
                case 2:
                  return 'Low';
                case 3:
                  return 'Medium';
                case 4:
                  return 'High';
                case 5:
                  return 'Very High';
                default:
                  return tick;
              }
            }}
            tick={{ fontSize: 12 }}
          />
          <YAxis
            type="number"
            dataKey="y"
            domain={[0, 5]}
            ticks={ticks}
            tickFormatter={(tick) => {
              switch (tick) {
                case 0:
                  return 'None';
                case 1:
                  return 'Insignificant';
                case 2:
                  return 'Minor';
                case 3:
                  return 'Moderate';
                case 4:
                  return 'Major';
                case 5:
                  return 'Catastrophic';
                default:
                  return tick;
              }
            }}
            tick={{ fontSize: 12 }}
          />
          <Tooltip
            cursor={{ strokeDasharray: '3 3' }}
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const data = payload[0].payload;
                return (
                  <div
                    className="custom-tooltip"
                    style={{
                      backgroundColor: 'white',
                      border: '1px solid #ccc',
                      padding: '10px',
                      borderRadius: '5px',
                    }}
                  >
                    <p><strong>Risks:</strong></p>
                    {data.titles?.map((title, index) => (
                      <p key={index}>{title}</p>
                    ))}
                  </div>
                );
              }
              return null;
            }}
          />
          <Scatter
            name="Risks"
            data={data}
            shape="circle"
            isAnimationActive={true}
            animationBegin={0}
            animationDuration={800}
            animationEasing="ease-out"
          >
            {data.map((entry, index) => (
              <circle
                key={`circle-${index}`}
                cx={entry.cx}
                cy={entry.cy}
                r={Math.min(entry.count * 8 + 8, 50)}
                fill={entry.fill}
              />
            ))}
          </Scatter>
        </ScatterChart>
      </ResponsiveContainer>
    );
  };

  const handleSaveSummary = async () => {
    const { error } = await supabase
      .from('risk_assessments')
      .update({ summary: editSummary })
      .eq('id', id);

    if (error) {
      setMessage(`Error: ${error.message}`);
      return;
    }

    setSummary(editSummary);
    setIsEditing(false);
    setMessage('Summary saved successfully!');
  };

  const handleGenerateAISummary = async () => {
    setLoading(true);
    const combinedData = {
      risks,
      vulnerabilities,
      consequences,
    };

    const prompt = `
      You are a risk assessment expert who has multiple years of expertice performing risk assessments and communicating the findings of a report to non technical management. 
      Based on the following json data, really think trough the different steps of your thought process but do not write out the thought process, instead generate a concise executive summary from the following data:
      Risks: ${JSON.stringify(risks)}
      Vulnerabilities: ${JSON.stringify(vulnerabilities)}
      Consequences: ${JSON.stringify(consequences)}
      Do not title the executive summary.
    `;

    try {
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-4",
        messages: [{ role: "user", content: prompt }],
        max_tokens: 500,
        n: 1,
        stop: null,
        temperature: 0.7,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      });

      setEditSummary(response.data.choices[0].message.content.trim());
    } catch (error) {
      setMessage(`Error generating AI summary: ${error.message}`);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box sx={{ mb: 4 }}>
      <Box sx={{ mt: 4 , mb: 4}}>
        {isEditing ? (
          <Box>
            <TextField
              fullWidth
              multiline
              value={editSummary}
              onChange={(e) => setEditSummary(e.target.value)}
              variant="outlined"
              sx={{ mb: 2 }}
              inputProps={{
                ref: textFieldRef,
                style: { height: textFieldHeight },
              }}
            />
            <Button variant="contained" color="primary" onClick={handleSaveSummary} sx={{ mr: 2 }} disabled={loading}>
              Save
            </Button>
            <Button variant="outlined" color="secondary" onClick={() => setIsEditing(false)} sx={{ mr: 2 }} disabled={loading}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={handleGenerateAISummary}
              startIcon={<AutoAwesomeIcon />}
              sx={{ backgroundColor: aiEnabled ? '#8a2be2' : '#ccc', color: 'white' }}
              disabled={!aiEnabled || loading}
            >
              {loading ? 'Generating...' : 'AI Summary'}
            </Button>
          </Box>
        ) : (
          <Box>
            <Typography variant="body1" paragraph>
              {summary || 'No summary available.'}
            </Typography>
            <Button variant="outlined" color="primary" onClick={() => setIsEditing(true)}>
              Edit Summary
            </Button>
          </Box>
        )}
      </Box>
      <FormControlLabel
        control={
          <Switch
            checked={useStepper}
            onChange={() => setUseStepper(!useStepper)}
            color="primary"
          />
        }
        label="Use Stepper View"
      />
      {useStepper ? (
        <>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label, index) => (
              <Step key={index} onClick={() => setActiveStep(index)}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <Box sx={{ mt: 2 }}>
            {activeStep === 0 && renderScatterPlot('original')}
            {activeStep === 1 && renderScatterPlot('potential')}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
            <Button
              disabled={activeStep === 0}
              onClick={() => setActiveStep((prev) => prev - 1)}
            >
              Back
            </Button>
            <Button
              disabled={activeStep === steps.length - 1}
              onClick={() => setActiveStep((prev) => prev + 1)}
            >
              Next
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Original Risk</Typography>
          {renderScatterPlot('original')}
          <Typography variant="h6" sx={{ mt: 4 }}>Potential Risk</Typography>
          {renderScatterPlot('potential')}
        </Box>
      )}
      
    </Box>
  );
};

export default ExecutiveSummary;
