import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import supabase from '../supabaseClient';
import MitigationList from './MitigationList';
import CommentList from './CommentList';
import Select from 'react-select';
import { AuthContext } from '../contexts/AuthContext';
import { Box, Button, TextField, Typography, MenuItem, FormControl, InputLabel, Select as MuiSelect, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Paper, Collapse } from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const VulnerabilityList = ({
  vulnerabilities,
  riskAssessmentId,
  onVulnerabilityAdded,
}) => {
  const { user } = useContext(AuthContext);
  const [showAddVulnerabilityForm, setShowAddVulnerabilityForm] = useState(false);
  const [newVulnerabilityTitle, setNewVulnerabilityTitle] = useState('');
  const [newVulnerabilityDescription, setNewVulnerabilityDescription] = useState('');
  const [newVulnerabilityScore, setNewVulnerabilityScore] = useState(1);
  const [newVulnerabilityType, setNewVulnerabilityType] = useState('');
  const [newVulnerabilitySubtype, setNewVulnerabilitySubtype] = useState('');
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [message, setMessage] = useState('');
  const [newMitigationTitle, setNewMitigationTitle] = useState('');
  const [newMitigationDescription, setNewMitigationDescription] = useState('');
  const [newMitigationScore, setNewMitigationScore] = useState(1);
  const [openMitigationFormId, setOpenMitigationFormId] = useState(null);
  const [newMitigation, setNewMitigation] = useState(null);
  const [users, setUsers] = useState([]);
  const [responsibleUser, setResponsibleUser] = useState(null);
  const [hasMitigations, setHasMitigations] = useState({});
  const [assets, setAssets] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [vulnerabilityToDelete, setVulnerabilityToDelete] = useState(null);
  const [editVulnerabilityId, setEditVulnerabilityId] = useState(null);
  const [editVulnerabilityTitle, setEditVulnerabilityTitle] = useState('');
  const [editVulnerabilityDescription, setEditVulnerabilityDescription] = useState('');
  const [editVulnerabilityScore, setEditVulnerabilityScore] = useState(1);
  const [editVulnerabilityType, setEditVulnerabilityType] = useState('');
  const [editVulnerabilitySubtype, setEditVulnerabilitySubtype] = useState('');
  const [editSelectedAsset, setEditSelectedAsset] = useState(null);
  const [commentSectionOpen, setCommentSectionOpen] = useState({});
  const [loadingAISuggestion, setLoadingAISuggestion] = useState(false);

  const vulnerabilityTypes = {
    Technical: ['Software', 'Hardware', 'Network', 'Configuration', 'Encryption', 'Unknown'],
    Human: ['Social Engineering', 'Insider Threat', 'Lack of Awareness'],
    Process: ['Policy Gaps', 'Process Failure', 'Third-Party Risk', 'Unknown'],
    Physical: ['Unauthorized Access', 'Environmental', 'Theft', 'Unknown'],
    Unknown: ['Unknown'],
  };

  useEffect(() => {
    const fetchUsersAndAssets = async () => {
      if (!user) return;

      const { data: userInfo, error: userInfoError } = await supabase
        .from('user_info')
        .select('organization')
        .eq('id', user.id)
        .single();

      if (userInfoError) {
        console.error('Error fetching user info:', userInfoError);
        return;
      }

      const organizationId = userInfo.organization;

      const { data: usersData, error: usersError } = await supabase
        .from('user_info')
        .select('id, name')
        .eq('organization', organizationId);

      if (usersError) {
        console.error('Error fetching users:', usersError);
      } else {
        setUsers(usersData.map(user => ({ value: user.id, label: user.name })));
      }

      const { data: assetsData, error: assetsError } = await supabase
        .from('assets')
        .select('id, name')
        .eq('organization_id', organizationId);

      if (assetsError) {
        console.error('Error fetching assets:', assetsError);
      } else {
        setAssets(assetsData.map(asset => ({ value: asset.id, label: asset.name })));
      }
    };

    fetchUsersAndAssets();
  }, [user]);

  const getAssetName = (assetId) => {
    const asset = assets.find(a => a.value === assetId);
    return asset ? asset.label : 'Unknown Asset';
  };

  const handleAddVulnerability = async (e) => {
    e.preventDefault();

    const originalScore = newVulnerabilityScore;

    const { data: newVulnerability, error: newVulnerabilityError } = await supabase
      .from('vulnerabilities')
      .insert([{ 
        title: newVulnerabilityTitle, 
        description: newVulnerabilityDescription, 
        score: newVulnerabilityScore,
        original_score: originalScore,
        potential_score: originalScore,
        risk_assessment_id: riskAssessmentId, 
        type: newVulnerabilityType, 
        subtype: newVulnerabilitySubtype,
        asset_id: selectedAsset.value,
      }])
      .select()
      .single();

    if (newVulnerabilityError) {
      setMessage(`Error: ${newVulnerabilityError.message}`);
      return;
    }

    if (newVulnerability && newVulnerability.id) {
      onVulnerabilityAdded(newVulnerability);
      setNewVulnerabilityTitle('');
      setNewVulnerabilityDescription('');
      setNewVulnerabilityScore(1);
      setNewVulnerabilityType('');
      setNewVulnerabilitySubtype('');
      setSelectedAsset(null);
      setShowAddVulnerabilityForm(false);
      setMessage('Vulnerability added successfully!');
    } else {
      setMessage('Error: Unable to add vulnerability.');
    }
  };

  const handleEditVulnerability = (vulnerability) => {
    setEditVulnerabilityId(vulnerability.id);
    setEditVulnerabilityTitle(vulnerability.title);
    setEditVulnerabilityDescription(vulnerability.description);
    setEditVulnerabilityScore(vulnerability.score);
    setEditVulnerabilityType(vulnerability.type);
    setEditVulnerabilitySubtype(vulnerability.subtype);
    setEditSelectedAsset({ value: vulnerability.asset_id, label: getAssetName(vulnerability.asset_id) });
  };

  const handleUpdateVulnerability = async (e) => {
    e.preventDefault();

    const { error } = await supabase
      .from('vulnerabilities')
      .update({
        title: editVulnerabilityTitle,
        description: editVulnerabilityDescription,
        score: editVulnerabilityScore,
        type: editVulnerabilityType,
        subtype: editVulnerabilitySubtype,
        asset_id: editSelectedAsset.value,
      })
      .eq('id', editVulnerabilityId);

    if (error) {
      setMessage(`Error: ${error.message}`);
      return;
    }

    setMessage('Vulnerability updated successfully!');
    setEditVulnerabilityId(null);
  };

  const handleAddMitigation = async (e, vulnerabilityId) => {
    e.preventDefault();

    const { data: newMitigation, error: newMitigationError } = await supabase
      .from('mitigations')
      .insert([{ 
        title: newMitigationTitle, 
        description: newMitigationDescription, 
        score: newMitigationScore, 
        vulnerability_id: vulnerabilityId, 
        responsible_user: responsibleUser.value 
      }])
      .select()
      .single();

    if (newMitigationError) {
      setMessage(`Error: ${newMitigationError.message}`);
      return;
    }

    setNewMitigation(newMitigation);
    setNewMitigationTitle('');
    setNewMitigationDescription('');
    setNewMitigationScore(1);
    setResponsibleUser(null);
    setOpenMitigationFormId(null);
    setMessage('Mitigation added successfully!');
  };

  const handleAISuggestion = async (vulnerability) => {
    setLoadingAISuggestion(true);
    const prompt = `
      You are an AI assistant that suggests mitigations for identified vulnerabilities in a risk assessment. Based on the following vulnerability details, suggest a concise mitigation title and description:
      Vulnerability Title: ${vulnerability.title}
      Vulnerability Description: ${vulnerability.description}
      Vulnerability Type: ${vulnerability.type}
      Vulnerability Subtype: ${vulnerability.subtype}
      Vulnerability Score: ${vulnerability.score}
      Do not title the title and description, only separate them with linebreaks.
    `;

    try {
      const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-4",
        messages: [{ role: "user", content: prompt }],
        max_tokens: 150,
        n: 1,
        stop: null,
        temperature: 0.7,
      }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
        },
      });

      const suggestion = response.data.choices[0].message.content.trim().split('\n');
      setNewMitigationTitle(suggestion[0]);
      setNewMitigationDescription(suggestion.slice(1).join(' '));
    } catch (error) {
      setMessage(`Error generating AI suggestion: ${error.message}`);
    } finally {
      setLoadingAISuggestion(false);
    }
  };

  const handleCancelAddMitigation = () => {
    setNewMitigationTitle('');
    setNewMitigationDescription('');
    setNewMitigationScore(1);
    setResponsibleUser(null);
    setOpenMitigationFormId(null);
  };

  const handleMitigationsChange = (vulnerabilityId, hasMitigation) => {
    setHasMitigations((prev) => ({ ...prev, [vulnerabilityId]: hasMitigation }));
  };

  const handleOpenDialog = (vulnerabilityId) => {
    setVulnerabilityToDelete(vulnerabilityId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setVulnerabilityToDelete(null);
  };

  const handleDeleteVulnerability = async () => {
    if (!vulnerabilityToDelete) return;

    const { error } = await supabase
      .from('vulnerabilities')
      .delete()
      .eq('id', vulnerabilityToDelete);

    if (error) {
      setMessage(`Error: ${error.message}`);
      return;
    }

    setMessage('Vulnerability deleted successfully!');
    setOpenDialog(false);
    setVulnerabilityToDelete(null);
  };

  const toggleCommentSection = (vulnerabilityId) => {
    setCommentSectionOpen((prev) => ({
      ...prev,
      [vulnerabilityId]: !prev[vulnerabilityId],
    }));
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
        Vulnerabilities
      </Typography>
      {vulnerabilities.length > 0 ? (
        <Box component="ul" sx={{ padding: 0, listStyle: 'none' }}>
          {vulnerabilities.map((vulnerability) => (
            <Box key={vulnerability.id} component="li" sx={{ marginBottom: 2 }}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography variant="h6">{vulnerability.title}</Typography>
                    <Typography>{vulnerability.description}</Typography>
                    <Typography>Score: {vulnerability.score}</Typography>
                    <Typography>Original Score: {vulnerability.original_score}</Typography>
                    <Typography>Potential Score: {vulnerability.potential_score}</Typography>
                    <Typography>Type: {vulnerability.type}</Typography>
                    <Typography>Subtype: {vulnerability.subtype}</Typography>
                    <Typography>Affected Asset: {getAssetName(vulnerability.asset_id)}</Typography>
                    <MitigationList
                      parentId={vulnerability.id}
                      type="vulnerability"
                      newMitigation={newMitigation}
                      users={users}
                      onEditClick={() => setOpenMitigationFormId(null)}
                      onMitigationsChange={(hasMitigation) => handleMitigationsChange(vulnerability.id, hasMitigation)}
                    />
                    {openMitigationFormId === vulnerability.id && (
                      <form onSubmit={(e) => handleAddMitigation(e, vulnerability.id)} className="add-mitigation-form">
                        <div className="form-group">
                          <TextField
                            fullWidth
                            label="Mitigation Title"
                            id={`mitigation-title-${vulnerability.id}`}
                            value={newMitigationTitle}
                            onChange={(e) => setNewMitigationTitle(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <TextField
                            fullWidth
                            multiline
                            minRows={3}
                            label="Mitigation Description"
                            id={`mitigation-description-${vulnerability.id}`}
                            value={newMitigationDescription}
                            onChange={(e) => setNewMitigationDescription(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <MuiSelect
                            fullWidth
                            label="Mitigation Score"
                            id={`mitigation-score-${vulnerability.id}`}
                            value={newMitigationScore}
                            onChange={(e) => setNewMitigationScore(parseInt(e.target.value))}
                            required
                          >
                            {[1, 2, 3, 4, 5].map((score) => (
                              <MenuItem key={score} value={score}>
                                {score}
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </div>
                        <div className="form-group">
                          <Select
                            id={`responsible-user-${vulnerability.id}`}
                            options={users}
                            value={responsibleUser}
                            onChange={setResponsibleUser}
                            isClearable
                            isSearchable
                            placeholder="Select user"
                            required
                          />
                        </div>
                        <Box display="flex" justifyContent="flex-start" mt={2}>
                          <Button type="submit" variant="contained" color="primary" sx={{ mr: 2 }}>
                            Add Mitigation
                          </Button>
                          <Button
                            onClick={handleCancelAddMitigation}
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 2 }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => handleAISuggestion(vulnerability)}
                            variant="contained"
                            color="primary"
                            startIcon={<AutoAwesomeIcon />}
                            disabled={loadingAISuggestion}
                          >
                            {loadingAISuggestion ? 'Generating...' : 'AI Suggestion'}
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                      <Button
                        onClick={() => setOpenMitigationFormId(vulnerability.id)}
                        color={!hasMitigations[vulnerability.id] ? 'primary' : 'inherit'}
                        disabled={hasMitigations[vulnerability.id]}
                        variant="contained"
                        sx={{ width: '100%', mb: 1 }}
                      >
                        Add Mitigation
                      </Button>
                      <Button
                        onClick={() => handleEditVulnerability(vulnerability)}
                        variant="outlined"
                        color="primary"
                        sx={{ mb: 1, width: '100%' }}
                      >
                        Edit Vulnerability
                      </Button>
                      <Button
                        onClick={() => toggleCommentSection(vulnerability.id)}
                        variant="outlined"
                        color="primary"
                        sx={{ mb: 1, width: '100%' }}
                      >
                        {commentSectionOpen[vulnerability.id] ? 'Hide Comments' : 'Show Comments'}
                      </Button>
                      <Button
                        onClick={() => handleOpenDialog(vulnerability.id)}
                        variant="outlined"
                        sx={{
                          color: 'red',
                          borderColor: 'red',
                          '&:hover': {
                            backgroundColor: 'red',
                            color: 'white',
                          },
                          mb: 1,
                          width: '100%',
                        }}
                      >
                        Delete Vulnerability
                      </Button>
                      
                      <Collapse in={commentSectionOpen[vulnerability.id]} timeout="auto" unmountOnExit>
                        <CommentList parentType="vulnerability" parentId={vulnerability.id} />
                      </Collapse>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography>No vulnerabilities found. Click "Add Vulnerability" to add one.</Typography>
      )}
      <Button onClick={() => setShowAddVulnerabilityForm(!showAddVulnerabilityForm)} variant="contained" color="secondary">
        {showAddVulnerabilityForm ? 'Cancel' : 'Add Vulnerability'}
      </Button>
      {showAddVulnerabilityForm && (
        <form onSubmit={handleAddVulnerability} className="add-vulnerability-form">
          <div className="form-group">
            <label htmlFor="vulnerability-title">Vulnerability Title:</label>
            <TextField
              fullWidth
              id="vulnerability-title"
              value={newVulnerabilityTitle}
              onChange={(e) => setNewVulnerabilityTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="vulnerability-description">Vulnerability Description:</label>
            <TextField
              fullWidth
              id="vulnerability-description"
              value={newVulnerabilityDescription}
              onChange={(e) => setNewVulnerabilityDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="vulnerability-score">Vulnerability Score:</label>
            <MuiSelect
              fullWidth
              id="vulnerability-score"
              value={newVulnerabilityScore}
              onChange={(e) => setNewVulnerabilityScore(parseInt(e.target.value))}
              required
            >
              {[1, 2, 3, 4, 5].map((score) => (
                <MenuItem key={score} value={score}>
                  {score}
                </MenuItem>
              ))}
            </MuiSelect>
          </div>
          <div className="form-group">
            <label htmlFor="vulnerability-type">Vulnerability Type:</label>
            <MuiSelect
              fullWidth
              id="vulnerability-type"
              value={newVulnerabilityType}
              onChange={(e) => {
                setNewVulnerabilityType(e.target.value);
                setNewVulnerabilitySubtype(''); // Reset subtype when type changes
              }}
              required
            >
              {Object.keys(vulnerabilityTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </MuiSelect>
          </div>
          <div className="form-group">
            <label htmlFor="vulnerability-subtype">Vulnerability Subtype:</label>
            <MuiSelect
              fullWidth
              id="vulnerability-subtype"
              value={newVulnerabilitySubtype}
              onChange={(e) => setNewVulnerabilitySubtype(e.target.value)}
              required
              disabled={!newVulnerabilityType}
            >
              {vulnerabilityTypes[newVulnerabilityType]?.map((subtype) => (
                <MenuItem key={subtype} value={subtype}>
                  {subtype}
                </MenuItem>
              ))}
            </MuiSelect>
          </div>
          <div className="form-group">
            <label htmlFor="vulnerability-asset">Affected Asset:</label>
            <Select
              id="vulnerability-asset"
              options={assets}
              value={selectedAsset}
              onChange={setSelectedAsset}
              isClearable
              isSearchable
              placeholder="Select asset"
              required
            />
          </div>
          <Box display="flex" justifyContent="flex-start" mt={2}>
            <Button type="submit" variant="contained" color="primary" sx={{ mr: 2 }}>
              Add Vulnerability
            </Button>
            <Button onClick={() => setShowAddVulnerabilityForm(false)} variant="contained" color="secondary">
              Cancel
            </Button>
          </Box>
        </form>
      )}
      {message && <Typography color="error">{message}</Typography>}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Vulnerability</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this vulnerability? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteVulnerability} sx={{ color: 'red' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={Boolean(editVulnerabilityId)} onClose={() => setEditVulnerabilityId(null)}>
        <DialogTitle>Edit Vulnerability</DialogTitle>
        <DialogContent>
          <form onSubmit={handleUpdateVulnerability}>
            <div className="form-group">
              <label htmlFor="edit-vulnerability-title">Vulnerability Title:</label>
              <TextField
                fullWidth
                id="edit-vulnerability-title"
                value={editVulnerabilityTitle}
                onChange={(e) => setEditVulnerabilityTitle(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="edit-vulnerability-description">Vulnerability Description:</label>
              <TextField
                fullWidth
                id="edit-vulnerability-description"
                value={editVulnerabilityDescription}
                onChange={(e) => setEditVulnerabilityDescription(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="edit-vulnerability-score">Vulnerability Score:</label>
              <MuiSelect
                fullWidth
                id="edit-vulnerability-score"
                value={editVulnerabilityScore}
                onChange={(e) => setEditVulnerabilityScore(parseInt(e.target.value))}
                required
              >
                {[1, 2, 3, 4, 5].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </MuiSelect>
            </div>
            <div className="form-group">
              <label htmlFor="edit-vulnerability-type">Vulnerability Type:</label>
              <MuiSelect
                fullWidth
                id="edit-vulnerability-type"
                value={editVulnerabilityType}
                onChange={(e) => {
                  setEditVulnerabilityType(e.target.value);
                  setEditVulnerabilitySubtype(''); // Reset subtype when type changes
                }}
                required
              >
                {Object.keys(vulnerabilityTypes).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </MuiSelect>
            </div>
            <div className="form-group">
              <label htmlFor="edit-vulnerability-subtype">Vulnerability Subtype:</label>
              <MuiSelect
                fullWidth
                id="edit-vulnerability-subtype"
                value={editVulnerabilitySubtype}
                onChange={(e) => setEditVulnerabilitySubtype(e.target.value)}
                required
                disabled={!editVulnerabilityType}
              >
                {vulnerabilityTypes[editVulnerabilityType]?.map((subtype) => (
                  <MenuItem key={subtype} value={subtype}>
                    {subtype}
                  </MenuItem>
                ))}
              </MuiSelect>
            </div>
            <div className="form-group">
              <label htmlFor="edit-vulnerability-asset">Affected Asset:</label>
              <Select
                id="edit-vulnerability-asset"
                options={assets}
                value={editSelectedAsset}
                onChange={setEditSelectedAsset}
                isClearable
                isSearchable
                placeholder="Select asset"
                required
              />
            </div>
            <DialogActions>
              <Button onClick={() => setEditVulnerabilityId(null)} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default VulnerabilityList;
