// src/pages/Landing.js
import React from 'react';
import HeroImage from "../components/HeroImage";

const Landing = () => {
  return (
    <div>
      <HeroImage />
    </div>
  );
};

export default Landing;
