import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import supabase from '../supabaseClient';
import '../styles/CreateRiskAssessment.css';

const CreateRiskAssessment = () => {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [message, setMessage] = useState('');
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { data: riskAssessment, error: riskAssessmentError } = await supabase
      .from('risk_assessments')
      .insert([{ name, description, user_id: user.id }])
      .select()
      .single();

    if (riskAssessmentError) {
      setMessage(`Error: ${riskAssessmentError.message}`);
      return;
    }

    if (riskAssessment && riskAssessment.id) {
      setMessage('Risk assessment created successfully!');
      navigate(`/risk-assessment/${riskAssessment.id}`);
    } else {
      setMessage('Error: Unable to create risk assessment.');
    }
  };

  return (
    <div className="create-risk-assessment-container">
      <h1>Create Risk Assessment</h1>
      <form onSubmit={handleSubmit} className="risk-assessment-form">
        <div className="form-group">
          <label htmlFor="name">Risk Assessment Name:</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="description">Description:</label>
          <textarea
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">Create Risk Assessment</button>
      </form>
      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default CreateRiskAssessment;
