import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import supabase from '../supabaseClient';
import MitigationList from './MitigationList';
import CommentList from './CommentList';
import Select from 'react-select';
import { AuthContext } from '../contexts/AuthContext';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Collapse,
} from '@mui/material';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import '../styles/ConsequenceList.css';

const consequenceTypes = {
  Operational: [
    'Business Continuity',
    'Operational Disruption',
    'Service Downtime',
    'Unknown',
  ],
  Financial: ['Revenue Loss', 'Cost Overrun', 'Financial Fraud', 'Unknown'],
  Reputational: [
    'Brand Damage',
    'Public Relations Crisis',
    'Customer Trust Loss',
    'Unknown',
  ],
  Legal: ['Compliance Violation', 'Litigation Risk', 'Regulatory Fines', 'Unknown'],
  Unknown: ['Unknown'],
};

const ConsequenceList = ({
  consequences,
  riskAssessmentId,
  onConsequenceAdded,
}) => {
  const { user } = useContext(AuthContext);
  const [showAddConsequenceForm, setShowAddConsequenceForm] = useState(false);
  const [newConsequenceTitle, setNewConsequenceTitle] = useState('');
  const [newConsequenceDescription, setNewConsequenceDescription] = useState('');
  const [newConsequenceScore, setNewConsequenceScore] = useState(1);
  const [newConsequenceType, setNewConsequenceType] = useState('');
  const [newConsequenceSubtype, setNewConsequenceSubtype] = useState('');
  const [message, setMessage] = useState('');
  const [newMitigationTitle, setNewMitigationTitle] = useState('');
  const [newMitigationDescription, setNewMitigationDescription] = useState('');
  const [newMitigationScore, setNewMitigationScore] = useState(1);
  const [openMitigationFormId, setOpenMitigationFormId] = useState(null);
  const [newMitigation, setNewMitigation] = useState(null);
  const [users, setUsers] = useState([]);
  const [responsibleUser, setResponsibleUser] = useState(null);
  const [hasMitigations, setHasMitigations] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [consequenceToDelete, setConsequenceToDelete] = useState(null);
  const [editConsequenceId, setEditConsequenceId] = useState(null);
  const [editConsequenceTitle, setEditConsequenceTitle] = useState('');
  const [editConsequenceDescription, setEditConsequenceDescription] = useState('');
  const [editConsequenceScore, setEditConsequenceScore] = useState(1);
  const [editConsequenceType, setEditConsequenceType] = useState('');
  const [editConsequenceSubtype, setEditConsequenceSubtype] = useState('');
  const [commentSectionOpen, setCommentSectionOpen] = useState({});
  const [loadingAISuggestion, setLoadingAISuggestion] = useState(false);

  const toggleCommentSection = (consequenceId) => {
    setCommentSectionOpen((prevState) => ({
      ...prevState,
      [consequenceId]: !prevState[consequenceId],
    }));
  };

  useEffect(() => {
    const fetchUsers = async () => {
      if (!user) return;

      const { data: orgData, error: orgError } = await supabase
        .from('user_info')
        .select('organization')
        .eq('id', user.id)
        .single();

      if (orgError) {
        console.error('Error fetching organization:', orgError);
        return;
      }

      const organizationId = orgData.organization;

      const { data: usersData, error: usersError } = await supabase
        .from('user_info')
        .select('id, name')
        .eq('organization', organizationId);

      if (usersError) {
        console.error('Error fetching users:', usersError);
      } else {
        setUsers(usersData.map((user) => ({ value: user.id, label: user.name })));
      }
    };

    fetchUsers();
  }, [user]);

  const handleAddConsequence = async (e) => {
    e.preventDefault();

    const originalScore = calculateOriginalScore(newConsequenceScore);

    const { data: newConsequence, error: newConsequenceError } = await supabase
      .from('consequences')
      .insert([
        {
          title: newConsequenceTitle,
          description: newConsequenceDescription,
          score: newConsequenceScore,
          original_score: originalScore,
          potential_score: originalScore, // Setting potential_score same as original_score
          type: newConsequenceType,
          subtype: newConsequenceSubtype,
          risk_assessment_id: riskAssessmentId,
        },
      ])
      .select()
      .single();

    if (newConsequenceError) {
      setMessage(`Error: ${newConsequenceError.message}`);
      return;
    }

    if (newConsequence && newConsequence.id) {
      onConsequenceAdded(newConsequence);
      setNewConsequenceTitle('');
      setNewConsequenceDescription('');
      setNewConsequenceScore(1);
      setNewConsequenceType('');
      setNewConsequenceSubtype('');
      setShowAddConsequenceForm(false);
      setMessage('Consequence added successfully!');
    } else {
      setMessage('Error: Unable to add consequence.');
    }
  };

  const calculateOriginalScore = (score) => {
    return score; // For now, original_score is the same as score
  };

  const handleEditConsequence = (consequence) => {
    setEditConsequenceId(consequence.id);
    setEditConsequenceTitle(consequence.title);
    setEditConsequenceDescription(consequence.description);
    setEditConsequenceScore(consequence.score);
    setEditConsequenceType(consequence.type);
    setEditConsequenceSubtype(consequence.subtype);
  };

  const handleUpdateConsequence = async (e) => {
    e.preventDefault();

    const { error } = await supabase
      .from('consequences')
      .update({
        title: editConsequenceTitle,
        description: editConsequenceDescription,
        score: editConsequenceScore,
        type: editConsequenceType,
        subtype: editConsequenceSubtype,
      })
      .eq('id', editConsequenceId);

    if (error) {
      setMessage(`Error: ${error.message}`);
      return;
    }

    setMessage('Consequence updated successfully!');
    setEditConsequenceId(null);
  };

  const handleAddMitigation = async (e, consequenceId) => {
    e.preventDefault();

    const { data: newMitigation, error: newMitigationError } = await supabase
      .from('mitigations')
      .insert([
        {
          title: newMitigationTitle,
          description: newMitigationDescription,
          score: newMitigationScore,
          consequence_id: consequenceId,
          responsible_user: responsibleUser.value,
        },
      ])
      .select()
      .single();

    if (newMitigationError) {
      setMessage(`Error: ${newMitigationError.message}`);
      return;
    }

    setNewMitigation(newMitigation);
    setNewMitigationTitle('');
    setNewMitigationDescription('');
    setNewMitigationScore(1);
    setResponsibleUser(null);
    setOpenMitigationFormId(null);
    setMessage('Mitigation added successfully!');
  };

  const handleAISuggestion = async (consequence) => {
    setLoadingAISuggestion(true);
    const prompt = `
      You are an AI assistant that suggests mitigations for identified consequences in a risk assessment. Based on the following consequence details, suggest a concise mitigation title and description:
      Consequence Title: ${consequence.title}
      Consequence Description: ${consequence.description}
      Consequence Type: ${consequence.type}
      Consequence Subtype: ${consequence.subtype}
      Consequence Score: ${consequence.score}
      Do not title the title and description, only separate them with linebreaks.
    `;

    try {
      const response = await axios.post(
        'https://api.openai.com/v1/chat/completions',
        {
          model: 'gpt-4',
          messages: [{ role: 'user', content: prompt }],
          max_tokens: 150,
          n: 1,
          stop: null,
          temperature: 0.7,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${process.env.REACT_APP_OPENAI_API_KEY}`,
          },
        }
      );

      const suggestion = response.data.choices[0].message.content.trim().split('\n');
      setNewMitigationTitle(suggestion[0]);
      setNewMitigationDescription(suggestion.slice(1).join(' '));
    } catch (error) {
      setMessage(`Error generating AI suggestion: ${error.message}`);
    } finally {
      setLoadingAISuggestion(false);
    }
  };

  const handleCancelAddMitigation = () => {
    setNewMitigationTitle('');
    setNewMitigationDescription('');
    setNewMitigationScore(1);
    setResponsibleUser(null);
    setOpenMitigationFormId(null);
  };

  const handleMitigationsChange = (consequenceId, hasMitigation) => {
    setHasMitigations((prev) => ({ ...prev, [consequenceId]: hasMitigation }));
  };

  const handleOpenDialog = (consequenceId) => {
    setConsequenceToDelete(consequenceId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setConsequenceToDelete(null);
  };

  const handleDeleteConsequence = async () => {
    if (!consequenceToDelete) return;

    const { error } = await supabase
      .from('consequences')
      .delete()
      .eq('id', consequenceToDelete);

    if (error) {
      setMessage(`Error: ${error.message}`);
      return;
    }

    setMessage('Consequence deleted successfully!');
    setOpenDialog(false);
    setConsequenceToDelete(null);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom sx={{ mt: 2 }}>
        Consequences
      </Typography>
      {consequences.length > 0 ? (
        <Box component="ul" sx={{ padding: 0, listStyle: 'none' }}>
          {consequences.map((consequence) => (
            <Box key={consequence.id} component="li" sx={{ marginBottom: 2 }}>
              <Paper elevation={3} sx={{ p: 2 }}>
                <Grid container spacing={2}>
                  <Grid item xs={9}>
                    <Typography variant="h6">{consequence.title}</Typography>
                    <Typography>{consequence.description}</Typography>
                    <Typography>Score: {consequence.score}</Typography>
                    <Typography>Original Score: {consequence.original_score}</Typography> {/* Display the original score */}
                    <Typography>Potential Score: {consequence.potential_score}</Typography> {/* Display the potential score */}
                    <Typography>Type: {consequence.type}</Typography>
                    <Typography>Subtype: {consequence.subtype}</Typography>
                    <MitigationList
                      parentId={consequence.id}
                      type="consequence"
                      newMitigation={newMitigation}
                      users={users}
                      onEditClick={() => setOpenMitigationFormId(null)}
                      onMitigationsChange={(hasMitigation) =>
                        handleMitigationsChange(consequence.id, hasMitigation)
                      }
                    />
                    {openMitigationFormId === consequence.id && (
                      <form
                        onSubmit={(e) => handleAddMitigation(e, consequence.id)}
                        className="add-mitigation-form"
                      >
                        <div className="form-group">
                          <TextField
                            fullWidth
                            label="Mitigation Title"
                            id={`mitigation-title-${consequence.id}`}
                            value={newMitigationTitle}
                            onChange={(e) => setNewMitigationTitle(e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group">
                          <TextField
                            fullWidth
                            label="Mitigation Description"
                            id={`mitigation-description-${consequence.id}`}
                            value={newMitigationDescription}
                            onChange={(e) => setNewMitigationDescription(e.target.value)}
                            required
                            multiline
                            rows={4}
                          />
                        </div>
                        <div className="form-group">
                          <MuiSelect
                            fullWidth
                            label="Mitigation Score"
                            id={`mitigation-score-${consequence.id}`}
                            value={newMitigationScore}
                            onChange={(e) => setNewMitigationScore(parseInt(e.target.value))}
                            required
                          >
                            {[1, 2, 3, 4, 5].map((score) => (
                              <MenuItem key={score} value={score}>
                                {score}
                              </MenuItem>
                            ))}
                          </MuiSelect>
                        </div>
                        <div className="form-group">
                          <Select
                            id={`responsible-user-${consequence.id}`}
                            options={users}
                            value={responsibleUser}
                            onChange={setResponsibleUser}
                            isClearable
                            isSearchable
                            placeholder="Select user"
                            required
                          />
                        </div>
                        <Box display="flex" justifyContent="flex-start" mt={2}>
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            sx={{ mr: 2 }}
                          >
                            Add Mitigation
                          </Button>
                          <Button
                            onClick={handleCancelAddMitigation}
                            variant="contained"
                            color="secondary"
                            sx={{ mr: 2 }}
                          >
                            Cancel
                          </Button>
                          <Button
                            onClick={() => handleAISuggestion(consequence)}
                            variant="contained"
                            color="primary"
                            startIcon={<AutoAwesomeIcon />}
                            disabled={loadingAISuggestion}
                          >
                            {loadingAISuggestion ? 'Generating...' : 'AI Suggestion'}
                          </Button>
                        </Box>
                      </form>
                    )}
                  </Grid>
                  <Grid item xs={3}>
                    <Box display="flex" flexDirection="column" alignItems="flex-end">
                      <Button
                        onClick={() => setOpenMitigationFormId(consequence.id)}
                        color={!hasMitigations[consequence.id] ? 'primary' : 'inherit'}
                        disabled={hasMitigations[consequence.id]}
                        variant="contained"
                        sx={{ mb: 1, width: '100%' }}
                      >
                        Add Mitigation
                      </Button>
                      <Button
                        onClick={() => handleEditConsequence(consequence)}
                        variant="outlined"
                        color="primary"
                        sx={{ mb: 1, width: '100%' }}
                      >
                        Edit Consequence
                      </Button>
                      <Button
                        onClick={() => toggleCommentSection(consequence.id)}
                        variant="outlined"
                        color="primary"
                        sx={{ mb: 1, width: '100%' }}
                      >
                        {commentSectionOpen[consequence.id]
                          ? 'Hide Comments'
                          : 'Show Comments'}
                      </Button>
                      <Button
                        onClick={() => handleOpenDialog(consequence.id)}
                        variant="outlined"
                        sx={{
                          color: 'red',
                          borderColor: 'red',
                          '&:hover': {
                            backgroundColor: 'red',
                            color: 'white',
                          },
                          mb: 1,
                          width: '100%',
                        }}
                      >
                        Delete Consequence
                      </Button>

                      <Collapse
                        in={commentSectionOpen[consequence.id]}
                        sx={{ width: '100%', mt: 1 }}
                      >
                        <CommentList parentType="consequence" parentId={consequence.id} />
                      </Collapse>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Box>
          ))}
        </Box>
      ) : (
        <Typography>No consequences found. Click "Add Consequence" to add one.</Typography>
      )}
      <Button
        onClick={() => setShowAddConsequenceForm(!showAddConsequenceForm)}
        variant="contained"
        color="secondary"
      >
        {showAddConsequenceForm ? 'Cancel' : 'Add Consequence'}
      </Button>
      {showAddConsequenceForm && (
        <form onSubmit={handleAddConsequence} className="add-consequence-form">
          <div className="form-group">
            <label htmlFor="consequence-title">Consequence Title:</label>
            <TextField
              fullWidth
              id="consequence-title"
              value={newConsequenceTitle}
              onChange={(e) => setNewConsequenceTitle(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="consequence-description">Consequence Description:</label>
            <TextField
              fullWidth
              id="consequence-description"
              value={newConsequenceDescription}
              onChange={(e) => setNewConsequenceDescription(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="consequence-score">Consequence Score:</label>
            <FormControl fullWidth required>
              <InputLabel id="consequence-score-label">Score</InputLabel>
              <MuiSelect
                labelId="consequence-score-label"
                id="consequence-score"
                value={newConsequenceScore}
                onChange={(e) => setNewConsequenceScore(parseInt(e.target.value))}
                required
              >
                {[1, 2, 3, 4, 5].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </MuiSelect>
            </FormControl>
          </div>
          <div className="form-group">
            <label htmlFor="consequence-type">Consequence Type:</label>
            <MuiSelect
              fullWidth
              id="consequence-type"
              value={newConsequenceType}
              onChange={(e) => {
                setNewConsequenceType(e.target.value);
                setNewConsequenceSubtype(''); // Reset subtype when type changes
              }}
              required
            >
              {Object.keys(consequenceTypes).map((type) => (
                <MenuItem key={type} value={type}>
                  {type}
                </MenuItem>
              ))}
            </MuiSelect>
          </div>
          <div className="form-group">
            <label htmlFor="consequence-subtype">Consequence Subtype:</label>
            <MuiSelect
              fullWidth
              id="consequence-subtype"
              value={newConsequenceSubtype}
              onChange={(e) => setNewConsequenceSubtype(e.target.value)}
              required
              disabled={!newConsequenceType}
            >
              {consequenceTypes[newConsequenceType]?.map((subtype) => (
                <MenuItem key={subtype} value={subtype}>
                  {subtype}
                </MenuItem>
              ))}
            </MuiSelect>
          </div>
          <Button type="submit" variant="contained" color="primary">
            Add Consequence
          </Button>
        </form>
      )}
      {message && <Typography color="error">{message}</Typography>}

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Delete Consequence</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this consequence? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConsequence} sx={{ color: 'red' }}>
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={Boolean(editConsequenceId)} onClose={() => setEditConsequenceId(null)}>
        <DialogTitle>Edit Consequence</DialogTitle>
        <DialogContent>
          <form onSubmit={handleUpdateConsequence}>
            <div className="form-group">
              <label htmlFor="edit-consequence-title">Consequence Title:</label>
              <TextField
                fullWidth
                id="edit-consequence-title"
                value={editConsequenceTitle}
                onChange={(e) => setEditConsequenceTitle(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="edit-consequence-description">Consequence Description:</label>
              <TextField
                fullWidth
                id="edit-consequence-description"
                value={editConsequenceDescription}
                onChange={(e) => setEditConsequenceDescription(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="edit-consequence-score">Consequence Score:</label>
              <MuiSelect
                fullWidth
                id="edit-consequence-score"
                value={editConsequenceScore}
                onChange={(e) => setEditConsequenceScore(parseInt(e.target.value))}
                required
              >
                {[1, 2, 3, 4, 5].map((score) => (
                  <MenuItem key={score} value={score}>
                    {score}
                  </MenuItem>
                ))}
              </MuiSelect>
            </div>
            <div className="form-group">
              <label htmlFor="edit-consequence-type">Consequence Type:</label>
              <MuiSelect
                fullWidth
                id="edit-consequence-type"
                value={editConsequenceType}
                onChange={(e) => {
                  setEditConsequenceType(e.target.value);
                  setEditConsequenceSubtype(''); // Reset subtype when type changes
                }}
                required
              >
                {Object.keys(consequenceTypes).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </MuiSelect>
            </div>
            <div className="form-group">
              <label htmlFor="edit-consequence-subtype">Consequence Subtype:</label>
              <MuiSelect
                fullWidth
                id="edit-consequence-subtype"
                value={editConsequenceSubtype}
                onChange={(e) => setEditConsequenceSubtype(e.target.value)}
                required
                disabled={!editConsequenceType}
              >
                {consequenceTypes[editConsequenceType]?.map((subtype) => (
                  <MenuItem key={subtype} value={subtype}>
                    {subtype}
                  </MenuItem>
                ))}
              </MuiSelect>
            </div>
            <DialogActions>
              <Button onClick={() => setEditConsequenceId(null)} color="primary">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default ConsequenceList;
