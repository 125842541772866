import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import { ThemeContext } from '../contexts/ThemeContext'; // Correct import
import { AppBar, Toolbar, IconButton, Typography, Button, Menu, MenuItem, Drawer, List, ListItem, ListItemText, useMediaQuery, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import '../App.css';

const Header = () => {
  const { user, logout } = useContext(AuthContext);
  const { theme, toggleTheme } = useContext(ThemeContext); // Use ThemeContext
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const themeContext = useTheme();
  const isMobile = useMediaQuery(themeContext.breakpoints.down('md'));

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await logout();
    navigate('/login');
  };

  const handleProfileClick = () => {
    navigate(`/user/${user.id}`);
  };

  const toggleDrawer = (open) => () => {
    setMenuOpen(open);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
      <MenuItem onClick={handleLogout}>Logout</MenuItem>
    </Menu>
  );

  const renderDrawer = (
    <Drawer anchor="left" open={menuOpen} onClose={toggleDrawer(false)}>
      <List>
        {user ? (
          <>
            <ListItem button onClick={handleProfileClick}>
              <ListItemText primary={user.name} />
            </ListItem>
            <ListItem button onClick={() => navigate('/welcome')}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button onClick={() => navigate('/my-assets')}>
              <ListItemText primary="My Assets" />
            </ListItem>
            {user.role === 'admin' && (
              <ListItem button onClick={() => navigate('/admin')}>
                <ListItemText primary="Admin" />
              </ListItem>
            )}
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem button onClick={() => navigate('/about')}>
              <ListItemText primary="About Us" />
            </ListItem>
            <ListItem button onClick={() => navigate('/pricing')}>
              <ListItemText primary="Pricing" />
            </ListItem>
            <ListItem button onClick={() => navigate('/register-for-beta')}>
              <ListItemText primary="Register For Beta" />
            </ListItem>
          </>
        )}
      </List>
    </Drawer>
  );

  return (
    <AppBar 
      position="fixed" 
      sx={{ 
        boxShadow: 'none', 
        bgcolor: theme.palette.mode === 'dark' 
          ? 'rgba(15, 28, 45, 0.95)' // dark mode background with 95% opacity
          : 'rgba(240, 240, 240, 0.95)', // light mode background with 95% opacity
        color: theme.palette.text.primary 
      }}
    >
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          Insight ISMS
        </Typography>
        {isMobile ? (
          <>
            <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
              <MenuIcon />
            </IconButton>
            {renderDrawer}
          </>
        ) : (
          <>
            {user ? (
              <>
                <Button onClick={handleProfileClick}>{user.name}</Button>
                <Button onClick={() => navigate('/welcome')}>Home</Button>
                <Button onClick={() => navigate('/my-assets')}>My Assets</Button>
                {user.role === 'admin' && (
                  <Button onClick={() => navigate('/admin')}>Admin</Button>
                )}
                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                >
                  <AccountCircle />
                </IconButton>
              </>
            ) : (
              <>
                <Button onClick={() => navigate('/about')} sx={{ color: theme.palette.text.primary }}>About Us</Button>
                <Button onClick={() => navigate('/pricing')} sx={{ color: theme.palette.text.primary, marginRight: 2 }}>Pricing</Button>
                <Button variant="contained" color="primary" onClick={() => navigate('/register-for-beta')}>
                  Register For Beta
                </Button>
              </>
            )}
          </>
        )}
        <IconButton edge="end" color="inherit" aria-label="toggle theme" onClick={toggleTheme}>
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </IconButton>
      </Toolbar>
      {renderMenu}
    </AppBar>
  );
};

export default Header;
