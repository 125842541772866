import React, { useState } from 'react';
import '../styles/HeroImage.css';
import heroImage from '../assets/bg.webp'; // Import the image
import supabase from '../supabaseClient';

export default function HeroImage() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted with email:', email);

    try {
      const { data, error } = await supabase
        .from('beta_signup')
        .insert([
          { email: email }, // Replace "some_column" with actual column name (assuming email)
        ])
        .select();

      if (error) {
        console.error('Error signing up for beta:', error);
        setMessage('An error occurred. Please try again.');
      } else {
        console.log('Signup response:', data);
        setMessage('Successfully signed up for beta!');
      }
    } catch (error) {
      console.error('Error:', error);
      setMessage('An error occurred. Please try again.');
    }

    setEmail('');
  };

  return (
    <div className="hero-container">
      <div className="hero-inner-container">
        <div className="hero-content">
          <h1 className="hero-title">Manage Risk Effectively</h1>
          <p className="hero-subtitle">
            Insight ISMS empowers organizations to manage their information security risk with precision and ease. By offering advanced analytics and automated compliance tools, it ensures proactive threat mitigation and streamlined ISO 27001 certification.
          </p>
          <form onSubmit={handleSubmit} className="hero-input-container">
            <input
              type="email"
              className="hero-input"
              placeholder="Enter your email to join the beta"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button type="submit" className="btn-submit">Join Beta</button>
          </form>
          {message && <p className="hero-message">{message}</p>}
        </div>
        <div
          className="hero-bg-image"
          style={{ backgroundImage: `url(${heroImage})` }} // Use the imported image
        />
      </div>
    </div>
  );
}
