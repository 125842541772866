import React from 'react';
import { Container, Box, Typography, Grid, Card, CardContent } from '@mui/material';

const About = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 8, mb: 8 }}>
      <Typography variant="h2" component="h1" gutterBottom align="left">
        About Us
      </Typography>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Meet the Creator: Sander Løken Berntsen
        </Typography>
        <Typography variant="body1" paragraph align="left">
          I am Sander Løken Berntsen, the mind behind Insight ISMS. With a master's degree in Information Security from the Norwegian University of Science and Technology (NTNU) and a PECB certification as a Risk Manager, I have dedicated my career to enhancing risk assessment and management practices. As the head of security consulting at a mid-sized firm, I specialize in the Norwegian health sector, focusing particularly on complex lab solutions.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Why Insight ISMS?
        </Typography>
        <Typography variant="body1" paragraph align="left">
          Insight ISMS was born out of a necessity to bridge the gap between technical risk assessments and organizational risk management. During my work in the health sector and other industries, I noticed a significant disconnect: there was no efficient way to link individual risk assessments with organization-wide risk tracking. This gap often left organizations blind to systemic vulnerabilities and repetitive issues that needed a unified approach.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Our Solution
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom align="left">
                  Efficiency and Structure
                </Typography>
                <Typography variant="body1" align="left">
                  Our tool streamlines the risk assessment process, saving assessors valuable time. With a structured approach and AI-powered suggestions for mitigations, Insight acts as a knowledge multiplier, enhancing the quality and speed of assessments.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom align="left">
                  Comprehensive Tracking
                </Typography>
                <Typography variant="body1" align="left">
                  We enable tracking of risks at both the asset and organizational levels. Stakeholders gain a clear understanding of operational risks, facilitating organization-wide strategies to tackle systemic issues.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h3" gutterBottom align="left">
                  Mitigation Management
                </Typography>
                <Typography variant="body1" align="left">
                  Insight ensures that mitigations are not just suggested but actively tracked. Stakeholders and responsible parties can monitor progress, ensuring that risks are effectively managed and mitigations are implemented.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Inspiration and Vision
        </Typography>
        <Typography variant="body1" paragraph align="left">
          After years of working on risk assessments, I realized the potential for significant time and cost savings through better tools. Insight ISMS aims to solve inefficiencies and make risk assessments a dynamic, ongoing process rather than a static, forgotten document. My vision is to bring risk assessments to life, making them an integral part of daily operations.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Solving Real Problems
        </Typography>
        <Typography variant="body1" paragraph align="left">
          Insight addresses the critical issue of tracking stakeholder decisions and the implementation of mitigations. In a complex IT landscape with increasing risks, losing track of this information can be detrimental. Insight enforces best practices, ensuring that organizations stay ahead in managing their security risks.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Customer Impact
        </Typography>
        <Typography variant="body1" paragraph align="left">
          Though we are in the pre-launch phase, Insight has already demonstrated its potential by enabling faster risk assessments and providing comprehensive data for organizational risk management. Our customers can look forward to a tool that not only saves time but also enhances their security posture.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Commitment to Excellence
        </Typography>
        <Typography variant="body1" paragraph align="left">
          We adhere to best practices in application development and data handling, ensuring that our customers' data is secure and compliant with regulations like GDPR. We offer flexibility for enterprises to store their data according to their needs.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Our Values and Mission
        </Typography>
        <Typography variant="body1" paragraph align="left">
          At Insight ISMS, we stand for trust, cooperation, and efficiency. Our mission is to create a tool that integrates risk assessments seamlessly into the operations of every organization facing cybersecurity risks.
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h4" component="h2" gutterBottom align="left">
          Looking Ahead
        </Typography>
        <Typography variant="body1" paragraph align="left">
          As we prepare for our beta test, we invite you to join us in revolutionizing risk management. Together, we can create a safer, more secure operational environment for your organization.
        </Typography>
      </Box>
    </Container>
  );
};

export default About;
