import React, { useRef, useState, useEffect } from 'react';
import { Box, Typography, Grid, Stepper, Step, StepLabel, Modal, IconButton, Button, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import step1Image from '../assets/step1.png';
import step2Image from '../assets/step2.png';
import step3Image from '../assets/step3.png';
import step4Image from '../assets/step4.png';
import step5Image from '../assets/step5.png';
import step6Image from '../assets/step6.png';
import supabase from '../supabaseClient';
import { track } from '@vercel/analytics';

const RiskAssessmentProcess = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [modalImage, setModalImage] = useState('');
  const stepRefs = useRef([]);
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleBetaSignup = async () => {
    if (email) {
      try {
        const { data, error } = await supabase.from('beta_signup').insert([{ email }]);
        if (error) throw error;
        setMessage(`Thank you for signing up for the beta, ${email}!`);
        setEmail('');

        // Track the beta signup event
        track('beta_signup', { email });
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
    } else {
      setMessage('Please enter a valid email address.');
    }
  };

  const handleImageClick = (image) => {
    setModalImage(image);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setModalImage('');
  };

  const steps = [
    { 
      label: 'Register Assets', 
      description: 'Begin by registering all your assets within the system. This includes all hardware such as servers, desktops, and mobile devices, as well as software applications, databases, and any other valuable resources that your organization relies on. Accurate asset registration is crucial as it forms the foundation for subsequent risk assessments. Make sure to include detailed information for each asset, such as its location, owner, and any relevant specifications or configurations. This step ensures that all assets are accounted for and protected within your information security management system.',
      image: step1Image
    },
    { 
      label: 'Perform Risk Assessments', 
      description: 'Risk assessors will perform detailed risk assessments to identify potential threats and vulnerabilities associated with each asset. During this phase, assessors will consider various risk factors such as the likelihood of a threat occurring and the potential impact on the organization. Each risk identified will be tied to the relevant asset to ensure a comprehensive understanding of where your organization\'s vulnerabilities lie. This thorough assessment helps prioritize which risks need immediate attention and which can be monitored over time.',
      image: step2Image
    },
    { 
      label: 'Create Mitigations', 
      description: 'Develop mitigation strategies for each identified risk to reduce its likelihood or impact. This could include implementing new security controls, enhancing existing ones, or making changes to operational processes. Mitigation strategies should be specific, actionable, and tailored to the particular risk and asset they address. Documenting these strategies in detail helps ensure that everyone involved understands the steps required to mitigate risks effectively. The goal is to reduce risks to an acceptable level, ensuring the safety and security of your assets.',
      image: step3Image
    },
    { 
      label: 'Risk Decision by Asset Owner', 
      description: 'The asset owner reviews the risks and decides whether they are acceptable, unacceptable, or acceptable with the proposed mitigations in place. This decision-making process involves evaluating the residual risk after mitigations have been applied and determining if it aligns with the organization\'s risk tolerance. If the risk is deemed unacceptable, further mitigations or alternative strategies may be needed. The asset owner\'s decision is crucial as it reflects the organization\'s overall risk management strategy and ensures accountability.',
      image: step4Image
    },
    { 
      label: 'Track Mitigation Progress', 
      description: 'Once mitigation strategies are in place, it is essential to track their progress to ensure that they are implemented effectively and within the agreed timeframe. This involves regular monitoring and reporting on the status of each mitigation action. Keeping track of progress helps identify any delays or issues that may arise, allowing for timely adjustments. This step ensures that all mitigation efforts are on track and that risks are being managed proactively.',
      image: step5Image
    },
    { 
      label: 'Overview and Track Risks Organization-Wide', 
      description: 'Maintain an overview of all risks across the organization to ensure comprehensive risk management. This involves consolidating risk information from all assets and mitigation activities into a central dashboard or report. Regular reviews of this consolidated risk data help senior management and stakeholders understand the organization\'s risk posture and make informed decisions. Tracking risks organization-wide also facilitates continuous improvement in the risk management process, ensuring that new risks are identified and managed effectively.',
      image: step6Image
    }
  ];

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = stepRefs.current.indexOf(entry.target);
            if (index !== -1) {
              setActiveStep(index);
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    stepRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      stepRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  return (
    <>
      <Box mt={8} display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h4" component="h2" gutterBottom>
          Risk Assessment Process
        </Typography>
        <Grid container spacing={4} alignItems="flex-start" justifyContent="center">
          <Grid item xs={12} md={10}>
            <Box display="flex" justifyContent="center">
              <Stepper activeStep={activeStep} orientation="vertical">
                {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>
                      <Box ref={(el) => (stepRefs.current[index] = el)} display="flex" flexDirection="row" alignItems="center">
                        <Box flexGrow={1} pr={2}>
                          <Typography variant="h6">{step.label}</Typography>
                          <Typography variant="body1">{step.description}</Typography>
                        </Box>
                        <Box flexShrink={0}>
                          <img 
                            src={step.image} 
                            alt={step.label} 
                            style={{ maxWidth: '400px', width: '100%', borderRadius: '8px', cursor: 'pointer' }} 
                            onClick={() => handleImageClick(step.image)} 
                          />
                        </Box>
                      </Box>
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>
          </Grid>
        </Grid>
        <Box mt={4}>
          <TextField
            fullWidth
            label="Enter your email to register for beta access"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button variant="contained" color="primary" size="large" onClick={handleBetaSignup} sx={{ marginTop: 2 }} fullWidth>
            Register for Beta
          </Button>
          {message && (
            <Typography variant="body1" color="secondary" mt={2}>
              {message}
            </Typography>
          )}
        </Box>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box 
          display="flex" 
          flexDirection="column" 
          alignItems="center" 
          justifyContent="center"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
          bgcolor="background.paper"
          boxShadow={24}
          p={4}
          maxWidth="90vw"
          maxHeight="90vh"
          overflow="auto"
        >
          <IconButton
            aria-label="close"
            onClick={handleCloseModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <img src={modalImage} alt="Enlarged step" style={{ width: '100%', height: 'auto' }} />
        </Box>
      </Modal>
    </>
  );
};

export default RiskAssessmentProcess;
