import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient';
import Select from 'react-select';
import { AuthContext } from '../contexts/AuthContext';
import { Container, Box, Typography, Button, TextField, Alert } from '@mui/material';
import AssetTable from '../components/AssetsTable'; // Import the AssetTable component

const MyAssets = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const [name, setName] = useState('');
  const [type, setType] = useState('');
  const [owner, setOwner] = useState(null);
  const [users, setUsers] = useState([]);
  const [message, setMessage] = useState('');
  const [showForm, setShowForm] = useState(false);
  const [assets, setAssets] = useState([]);
  const [organizationId, setOrganizationId] = useState(null);

  useEffect(() => {
    const fetchOrganizationAndUsers = async () => {
      if (!user) return;

      // Fetch the organization ID for the logged-in user
      const { data: orgData, error: orgError } = await supabase
        .from('user_info')
        .select('organization')
        .eq('id', user.id)
        .single();

      if (orgError) {
        console.error('Error fetching organization:', orgError);
        return;
      }

      const organizationId = orgData.organization;
      setOrganizationId(organizationId);

      // Fetch users belonging to the organization
      const { data: usersData, error: usersError } = await supabase
        .from('user_info')
        .select('id, email')
        .eq('organization', organizationId);

      if (usersError) {
        console.error('Error fetching users from organization:', usersError);
      } else {
        setUsers(usersData.map((user) => ({ value: user.id, label: user.email })));
      }

      // Fetch assets owned by the logged-in user
      const { data: assetsData, error: assetsError } = await supabase
        .from('assets')
        .select('*')
        .eq('owner_id', user.id);

      if (assetsError) {
        console.error('Error fetching assets:', assetsError);
      } else {
        setAssets(assetsData);
      }
    };

    fetchOrganizationAndUsers();
  }, [user]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { data, error } = await supabase
      .from('assets')
      .insert([{ name, type, owner_id: owner.value, organization_id: organizationId }]);

    if (error) {
      setMessage(`Error: ${error.message}`);
    } else {
      setMessage('Asset added successfully!');
      setName('');
      setType('');
      setOwner(null);
      setShowForm(false); // Hide the form after successful submission

      // Fetch updated assets list
      const { data: updatedAssets, error: updatedAssetsError } = await supabase
        .from('assets')
        .select('*')
        .eq('owner_id', user.id);

      if (updatedAssetsError) {
        console.error('Error fetching updated assets:', updatedAssetsError);
      } else {
        setAssets(updatedAssets);
      }
    }
  };

  const toggleForm = () => {
    setShowForm(!showForm);
  };

  const handleAssetClick = (assetId) => {
    navigate(`/asset/${assetId}`);
  };

  return (
    <Container maxWidth="md" sx={{ pt: 10 }}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" textAlign="center" mt={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          My Assets
        </Typography>
        <Button variant="contained" color="primary" onClick={toggleForm} sx={{ mb: 3 }}>
          {showForm ? 'Cancel' : 'Add Asset'}
        </Button>
        {showForm && (
          <Box component="form" onSubmit={handleSubmit} sx={{ mb: 3, width: '100%', maxWidth: '400px' }}>
            <TextField
              fullWidth
              label="Asset Name"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            <TextField
              fullWidth
              label="Asset Type"
              variant="outlined"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
              sx={{ mb: 2 }}
            />
            <Select
              id="owner_id"
              options={users}
              value={owner}
              onChange={setOwner}
              isClearable
              isSearchable
              placeholder="Select owner"
              required
              styles={{
                control: (base) => ({
                  ...base,
                  marginBottom: '16px'
                })
              }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Add Asset
            </Button>
          </Box>
        )}
        {message && (
          <Alert severity={message.startsWith('Error') ? 'error' : 'success'} sx={{ mb: 3 }}>
            {message}
          </Alert>
        )}
        <Box width="100%">
          <AssetTable assets={assets} handleAssetClick={handleAssetClick} />
        </Box>
      </Box>
    </Container>
  );
};

export default MyAssets;
