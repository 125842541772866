import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../supabaseClient';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  Paper,
  Container,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow
} from '@mui/material';
import { Doughnut } from 'react-chartjs-2';
import { Chart, ArcElement } from 'chart.js';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';

Chart.register(ArcElement);

const AssetDetails = () => {
  const { id } = useParams();
  const [asset, setAsset] = useState(null);
  const [owner, setOwner] = useState(null);
  const [loading, setLoading] = useState(true);
  const [riskDistribution, setRiskDistribution] = useState({
    low: 0,
    medium: 0,
    high: 0,
    extreme: 0,
  });
  const [mitigations, setMitigations] = useState([]);

  useEffect(() => {
    const fetchAssetDetails = async () => {
      // Fetch asset details
      const { data: assetData, error: assetError } = await supabase
        .from('assets')
        .select('*')
        .eq('id', id)
        .single();

      if (assetError) {
        console.error('Error fetching asset:', assetError);
        setLoading(false);
        return;
      }

      setAsset(assetData);

      // Fetch owner details
      const { data: ownerData, error: ownerError } = await supabase
        .from('user_info')
        .select('name')
        .eq('id', assetData.owner_id)
        .single();

      if (ownerError) {
        console.error('Error fetching owner:', ownerError);
      } else {
        setOwner(ownerData.name);
      }

      // Fetch risks related to the asset
      const { data: risksData, error: risksError } = await supabase
        .from('risks')
        .select('*')
        .eq('asset_id', id);

      if (risksError) {
        console.error('Error fetching risks:', risksError);
        return;
      }

      // Fetch vulnerabilities and consequences
      const { data: vulnerabilitiesData, error: vulnerabilitiesError } = await supabase
        .from('vulnerabilities')
        .select('*');

      const { data: consequencesData, error: consequencesError } = await supabase
        .from('consequences')
        .select('*');

      if (vulnerabilitiesError || consequencesError) {
        console.error('Error fetching vulnerabilities or consequences:', vulnerabilitiesError || consequencesError);
        return;
      }

      // Fetch mitigations
      const { data: mitigationsData, error: mitigationsError } = await supabase
        .from('mitigations')
        .select('*');

      if (mitigationsError) {
        console.error('Error fetching mitigations:', mitigationsError);
        return;
      }

      // Fetch user info for responsible users
      const userIds = mitigationsData.map(mitigation => mitigation.responsible_user).filter(id => id);
      const { data: usersData, error: usersError } = await supabase
        .from('user_info')
        .select('id, name')
        .in('id', userIds);

      if (usersError) {
        console.error('Error fetching users:', usersError);
        return;
      }

      const userMap = Object.fromEntries(usersData.map(user => [user.id, user.name]));

      // Filter mitigations related to vulnerabilities or consequences linked to the current asset
      const relatedMitigations = mitigationsData.filter((mitigation) => {
        const relatedVulnerability = vulnerabilitiesData.find(
          (vulnerability) => vulnerability.id === mitigation.vulnerability_id && vulnerability.asset_id === id
        );
        const relatedConsequence = consequencesData.find(
          (consequence) => consequence.id === mitigation.consequence_id && consequence.asset_id === id
        );
        return relatedVulnerability || relatedConsequence;
      }).map(mitigation => ({
        ...mitigation,
        responsible_user_name: userMap[mitigation.responsible_user] || 'Unknown'
      }));

      setMitigations(relatedMitigations);

      // Calculate risk scores
      const riskScores = risksData.map((risk) => {
        const vulnerability = vulnerabilitiesData.find(v => v.id === risk.vulnerability_id);
        const consequence = consequencesData.find(c => c.id === risk.consequence_id);
        return vulnerability && consequence ? vulnerability.score * consequence.score : 0;
      });

      // Categorize risk scores
      const distribution = { low: 0, medium: 0, high: 0, extreme: 0 };
      riskScores.forEach(score => {
        if (score <= 6) distribution.low += 1;
        else if (score <= 12) distribution.medium += 1;
        else if (score <= 18) distribution.high += 1;
        else distribution.extreme += 1;
      });

      setRiskDistribution(distribution);

      setLoading(false);
    };

    fetchAssetDetails();
  }, [id]);

  const getStatusIcon = (status) => {
    switch (status) {
      case 'Completed':
        return <CheckCircleIcon style={{ color: 'green', marginRight: 8, verticalAlign: 'middle' }} />;
      case 'Failed':
        return <ErrorIcon style={{ color: 'red', marginRight: 8, verticalAlign: 'middle' }} />;
      case 'New':
        return <InfoIcon style={{ color: 'blue', marginRight: 8, verticalAlign: 'middle' }} />;
      case 'In Progress':
        return <HourglassEmptyIcon style={{ color: 'orange', marginRight: 8, verticalAlign: 'middle' }} />;
      default:
        return null;
    }
  };

  if (loading) {
    return (
      <Container sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Container>
    );
  }

  if (!asset) {
    return (
      <Container sx={{ mt: 12 }}>
        <Typography variant="h4" gutterBottom>
          Asset Not Found
        </Typography>
      </Container>
    );
  }

  const data = {
    labels: ['Low', 'Medium', 'High', 'Extreme'],
    datasets: [
      {
        data: [riskDistribution.low, riskDistribution.medium, riskDistribution.high, riskDistribution.extreme],
        backgroundColor: ['#4caf50', '#ffeb3b', '#ff9800', '#f44336'],
        hoverBackgroundColor: ['#388e3c', '#fbc02d', '#f57c00', '#d32f2f'],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: '50%',
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 12 }}>
      <Paper sx={{ p: 4, pb: 10 }}>
        <Typography variant="h4" gutterBottom>
          {asset.name}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Type:</strong> {asset.type}
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>Owner:</strong> {owner || 'Unknown'}
        </Typography>
        <Box sx={{ height: '300px', width: '100%', mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Risk Distribution
          </Typography>
          <Doughnut data={data} options={options} />
        </Box>
        <Box sx={{ mt: 4 }}>
          <Typography variant="h6" gutterBottom>
            Mitigations
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Responsible User</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {mitigations.map((mitigation) => (
                  <TableRow key={mitigation.id}>
                    <TableCell>{mitigation.title}</TableCell>
                    <TableCell>
                      {getStatusIcon(mitigation.status)}
                      <span style={{ verticalAlign: 'middle' }}>{mitigation.status}</span>
                    </TableCell>
                    <TableCell>{mitigation.responsible_user_name}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Paper>
    </Container>
  );
};

export default AssetDetails;
