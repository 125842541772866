import React from 'react';
import { Box, Typography, Grid, Card } from '@mui/material';
import { styled } from '@mui/system';

const CustomerCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[2],
  textAlign: 'left',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
}));

const Customers = () => {
  return (
    <Box sx={{ backgroundColor: '#0077B6', mt: 8, pt: 3, pb: 6 }}>
      <Box textAlign="left" mb={4} pl={2}>
        <Typography variant="h4" component="h2" color="white">
          Our Customers
        </Typography>
      </Box>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomerCard>
            <Typography variant="h6" component="h3" gutterBottom>
              Independent Risk Consultants
            </Typography>
            <Typography variant="body1">
              Tailored solutions for independent consultants managing risk for multiple clients.
            </Typography>
          </CustomerCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomerCard>
            <Typography variant="h6" component="h3" gutterBottom>
              Consultant Teams
            </Typography>
            <Typography variant="body1">
              Collaborative tools designed for teams of consultants working on complex projects.
            </Typography>
          </CustomerCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomerCard>
            <Typography variant="h6" component="h3" gutterBottom>
              Medium Sized Businesses
            </Typography>
            <Typography variant="body1">
              Scalable risk management solutions for growing businesses.
            </Typography>
          </CustomerCard>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomerCard>
            <Typography variant="h6" component="h3" gutterBottom>
              Enterprise
            </Typography>
            <Typography variant="body1">
              Comprehensive risk management for large enterprises with extensive operations.
            </Typography>
          </CustomerCard>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Customers;
