import React, { useState } from 'react';
import { Container, Box, Typography, Button, TextField } from '@mui/material';
import supabase from '../supabaseClient';

const RegisterForBeta = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleBetaSignup = async () => {
    if (email) {
      try {
        // Insert the email into the beta_signup table
        const { data, error } = await supabase
          .from('beta_signup')
          .insert([{ email }]);

        if (error) {
          throw error;
        }

        // Display success message
        setMessage(`Thank you for signing up for the beta, ${email}!`);
        setEmail('');
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
    } else {
      setMessage('Please enter a valid email address.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        height="80vh"
        textAlign="center"
        bgcolor="background.default"
        p={3}
      >
        <Typography variant="h4" component="h1" gutterBottom>
          Register For Beta
        </Typography>
        <Typography variant="h6" component="p" gutterBottom>
          Enter your email to join our beta program.
        </Typography>
        <Box mt={4} width="100%" maxWidth="400px">
          <TextField
            fullWidth
            label="Enter your email"
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleBetaSignup}
            sx={{ marginTop: 2 }}
            fullWidth
          >
            Sign Up
          </Button>
          {message && (
            <Typography variant="body1" color="secondary" mt={2}>
              {message}
            </Typography>
          )}
        </Box>
      </Box>
    </Container>
  );
};

export default RegisterForBeta;
