import React, { useState, useEffect } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Select, MenuItem, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { CheckCircle as CheckCircleIcon, Error as ErrorIcon, Info as InfoIcon, HourglassEmpty as HourglassEmptyIcon } from '@mui/icons-material';
import supabase from '../supabaseClient';

const getStatusIcon = (status) => {
  switch (status) {
    case 'Completed':
      return <CheckCircleIcon style={{ color: 'green', marginRight: 8, verticalAlign: 'middle' }} />;
    case 'Failed':
      return <ErrorIcon style={{ color: 'red', marginRight: 8, verticalAlign: 'middle' }} />;
    case 'New':
      return <InfoIcon style={{ color: 'blue', marginRight: 8, verticalAlign: 'middle' }} />;
    case 'In Progress':
      return <HourglassEmptyIcon style={{ color: 'orange', marginRight: 8, verticalAlign: 'middle' }} />;
    default:
      return null;
  }
};

const MitigationsTable = ({ user }) => {
  const [mitigations, setMitigations] = useState([]);
  const [assets, setAssets] = useState([]);
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [consequences, setConsequences] = useState([]);
  const [editingStatus, setEditingStatus] = useState(null);
  const [hideCompletedFailed, setHideCompletedFailed] = useState(false);

  useEffect(() => {
    const fetchMitigations = async () => {
      if (!user) return;

      const { data: mitigationsData, error: mitigationsError } = await supabase
        .from('mitigations')
        .select('*')
        .eq('responsible_user', user.id);

      if (mitigationsError) {
        console.error('Error fetching mitigations:', mitigationsError);
      } else {
        setMitigations(mitigationsData);
      }

      const { data: assetsData, error: assetsError } = await supabase
        .from('assets')
        .select('*');

      if (assetsError) {
        console.error('Error fetching assets:', assetsError);
      } else {
        setAssets(assetsData);
      }

      const { data: vulnerabilitiesData, error: vulnerabilitiesError } = await supabase
        .from('vulnerabilities')
        .select('*');

      if (vulnerabilitiesError) {
        console.error('Error fetching vulnerabilities:', vulnerabilitiesError);
      } else {
        setVulnerabilities(vulnerabilitiesData);
      }

      const { data: consequencesData, error: consequencesError } = await supabase
        .from('consequences')
        .select('*');

      if (consequencesError) {
        console.error('Error fetching consequences:', consequencesError);
      } else {
        setConsequences(consequencesData);
      }
    };

    fetchMitigations();
  }, [user]);

  const handleChangeStatus = async (mitigationId, newStatus) => {
    const { data, error } = await supabase
      .from('mitigations')
      .update({ status: newStatus })
      .eq('id', mitigationId);

    if (error) {
      console.error('Error updating mitigation status:', error);
    } else {
      setMitigations(mitigations.map(mitigation =>
        mitigation.id === mitigationId ? { ...mitigation, status: newStatus } : mitigation
      ));
      setEditingStatus(null);
    }
  };

  const getAssetName = (vulnerabilityId, consequenceId) => {
    const vulnerability = vulnerabilities.find(v => v.id === vulnerabilityId);
    const consequence = consequences.find(c => c.id === consequenceId);
    const assetId = vulnerability ? vulnerability.asset_id : (consequence ? consequence.asset_id : null);
    const asset = assets.find(asset => asset.id === assetId);
    return asset ? asset.name : 'Unknown Asset';
  };

  const statusOrder = {
    'New': 1,
    'In Progress': 2,
    'Failed': 3,
    'Completed': 4,
  };

  const filteredMitigations = hideCompletedFailed
    ? mitigations.filter(mitigation => mitigation.status !== 'Completed' && mitigation.status !== 'Failed')
    : mitigations;

  const sortedMitigations = filteredMitigations.sort((a, b) => statusOrder[a.status] - statusOrder[b.status]);

  if (mitigations.length === 0) {
    return null; // Don't render anything if there are no mitigations
  }

  return (
    <Box mt={4}>
      <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6">Mitigations</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={hideCompletedFailed}
              onChange={() => setHideCompletedFailed(!hideCompletedFailed)}
            />
          }
          label="Hide Completed and Failed"
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Mitigation</TableCell>
              <TableCell>Asset</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sortedMitigations.map((mitigation) => (
              <TableRow key={mitigation.id}>
                <TableCell>{mitigation.title}</TableCell>
                <TableCell>{getAssetName(mitigation.vulnerability_id, mitigation.consequence_id)}</TableCell>
                <TableCell>
                  {getStatusIcon(mitigation.status)} {mitigation.status}
                </TableCell>
                <TableCell>
                  {editingStatus === mitigation.id ? (
                    <Select
                      value={mitigation.status}
                      onChange={(e) => handleChangeStatus(mitigation.id, e.target.value)}
                      onBlur={() => setEditingStatus(null)}
                      sx={{ width: '165px', height: '36px' }} // Adjust width and height
                    >
                      <MenuItem value="New">New</MenuItem>
                      <MenuItem value="In Progress">In Progress</MenuItem>
                      <MenuItem value="Completed">Completed</MenuItem>
                      <MenuItem value="Failed">Failed</MenuItem>
                    </Select>
                  ) : (
                    <Button variant="outlined" onClick={() => setEditingStatus(mitigation.id)} sx={{ width: '165px', height: '36px' }}>
                      Change Status
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default MitigationsTable;
