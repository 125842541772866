import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient';
import '../styles/OrganizationOptions.css';

const OrganizationOptions = () => {
  const navigate = useNavigate();
  const [inviteLink, setInviteLink] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (error || !data.user) {
        navigate('/login'); // Redirect to login if user is not authenticated
      } else {
        setUser(data.user);
      }
    };

    fetchUser();
  }, [navigate]);

  const handleJoinOrganization = async (e) => {
    e.preventDefault();

    const { data: organization, error: orgError } = await supabase
      .from('organizations')
      .select('*')
      .eq('invite_link', inviteLink)
      .single();

    if (orgError || !organization) {
      setError('Invalid invite link');
      return;
    }

    const { error: userInfoError } = await supabase
      .from('user_info')
      .update({ organization: organization.id })
      .eq('id', user.id);

    if (userInfoError) {
      setError('Error joining organization');
    } else {
      navigate('/welcome');
    }
  };

  const handleCreateOrganization = () => {
    navigate('/create-organization');
  };

  return (
    <div className="organization-options-container">
      <h2>Join or Create an Organization</h2>
      {error && <p className="error">{error}</p>}
      <button onClick={handleCreateOrganization} className="btn btn-primary">
        Create Organization
      </button>
      <form onSubmit={handleJoinOrganization}>
        <div className="form-group">
          <label htmlFor="invite-link">Invite Link:</label>
          <input
            type="text"
            id="invite-link"
            value={inviteLink}
            onChange={(e) => setInviteLink(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-secondary">
          Join Organization
        </button>
      </form>
    </div>
  );
};

export default OrganizationOptions;
