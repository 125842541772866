import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import supabase from '../supabaseClient';
import VulnerabilityList from '../components/VulnerabilityList';
import ConsequenceList from '../components/ConsequenceList';
import CommentList from '../components/CommentList';
import ExecutiveSummary from '../components/ExecutiveSummary'; // Import ExecutiveSummary
import { AuthContext } from '../contexts/AuthContext';
import {
  Box,
  Button,
  TextField,
  Typography,
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse
} from '@mui/material';

const RiskAssessmentDetails = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext); // Fetch user context
  const [riskAssessment, setRiskAssessment] = useState(null);
  const [assessor, setAssessor] = useState(null);
  const [risks, setRisks] = useState([]);
  const [vulnerabilities, setVulnerabilities] = useState([]);
  const [consequences, setConsequences] = useState([]);
  const [assets, setAssets] = useState([]);
  const [newRiskName, setNewRiskName] = useState('');
  const [newRiskDescription, setNewRiskDescription] = useState('');
  const [newRiskVulnerabilityId, setNewRiskVulnerabilityId] = useState('');
  const [newRiskConsequenceId, setNewRiskConsequenceId] = useState('');
  const [newRiskAssetId, setNewRiskAssetId] = useState('');
  const [message, setMessage] = useState('');
  const [summary, setSummary] = useState(''); // Add state for summary
  const [showAddRiskForm, setShowAddRiskForm] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [riskToDelete, setRiskToDelete] = useState(null);
  const [commentSectionOpen, setCommentSectionOpen] = useState({});
  const [aiEnabled, setAiEnabled] = useState(false); // State to check if AI is enabled

  useEffect(() => {
    const fetchRiskAssessmentDetails = async () => {
      const { data: riskAssessmentData, error: riskAssessmentError } = await supabase
        .from('risk_assessments')
        .select('*')
        .eq('id', id)
        .single();

      if (riskAssessmentError) {
        console.error('Error fetching risk assessment:', riskAssessmentError);
        return;
      }

      setRiskAssessment(riskAssessmentData);
      setSummary(riskAssessmentData.summary || ''); // Set the initial summary

      const { data: assessorData, error: assessorError } = await supabase
        .from('user_info')
        .select('name')
        .eq('id', riskAssessmentData.user_id)
        .single();

      if (assessorError) {
        console.error('Error fetching assessor:', assessorError);
        return;
      }

      setAssessor(assessorData.name);

      const { data: risksData, error: risksError } = await supabase
        .from('risks')
        .select('*')
        .eq('risk_assessment_id', id);

      if (risksError) {
        console.error('Error fetching risks:', risksError);
        return;
      }

      setRisks(risksData.filter(risk => !risk.deleted));

      const { data: vulnerabilitiesData, error: vulnerabilitiesError } = await supabase
        .from('vulnerabilities')
        .select('*')
        .eq('risk_assessment_id', id);

      if (vulnerabilitiesError) {
        console.error('Error fetching vulnerabilities:', vulnerabilitiesError);
        return;
      }

      setVulnerabilities(vulnerabilitiesData);

      const { data: consequencesData, error: consequencesError } = await supabase
        .from('consequences')
        .select('*')
        .eq('risk_assessment_id', id);

      if (consequencesError) {
        console.error('Error fetching consequences:', consequencesError);
        return;
      }

      setConsequences(consequencesData);

      const { data: assetsData, error: assetsError } = await supabase
        .from('assets')
        .select('*');

      if (assetsError) {
        console.error('Error fetching assets:', assetsError);
        return;
      }

      setAssets(assetsData);

      // Fetch organization info to check if AI is enabled
      if (user) {
        const { data: userInfo, error: userInfoError } = await supabase
          .from('user_info')
          .select('organization')
          .eq('id', user.id)
          .single();

        if (userInfoError) {
          console.error('Error fetching user info:', userInfoError);
        } else {
          const { data: orgData, error: orgError } = await supabase
            .from('organizations')
            .select('ai')
            .eq('id', userInfo.organization)
            .single();

          if (orgError) {
            console.error('Error fetching organization info:', orgError);
          } else {
            setAiEnabled(orgData.ai);
          }
        }
      }
    };

    fetchRiskAssessmentDetails();
  }, [id, user]);

  const handleAddRisk = async (e) => {
    e.preventDefault();

    const { data: newRisk, error: newRiskError } = await supabase
      .from('risks')
      .insert([{ name: newRiskName, description: newRiskDescription, risk_assessment_id: id, vulnerability_id: newRiskVulnerabilityId, consequence_id: newRiskConsequenceId, asset_id: newRiskAssetId }])
      .select()
      .single();

    if (newRiskError) {
      setMessage(`Error: ${newRiskError.message}`);
      return;
    }

    if (newRisk && newRisk.id) {
      const riskScore = calculateRiskScore(newRisk.vulnerability_id, newRisk.consequence_id);
      const riskPotentialScore = calculateRiskPotentialScore(newRisk.vulnerability_id, newRisk.consequence_id);

      // Update the new risk with the calculated risk scores
      const { data: updatedRisk, error: updateRiskError } = await supabase
        .from('risks')
        .update({ original_score: riskScore, potential_score: riskPotentialScore })
        .eq('id', newRisk.id)
        .select()
        .single();

      if (updateRiskError) {
        setMessage(`Error: ${updateRiskError.message}`);
        return;
      }

      const { error: newRiskOverTimeError } = await supabase
        .from('risk_over_time')
        .insert([{ risk_id: updatedRisk.id, risk_score: riskScore }]);

      if (newRiskOverTimeError) {
        setMessage(`Error: ${newRiskOverTimeError.message}`);
        return;
      }

      setRisks([...risks, updatedRisk]);
      setNewRiskName('');
      setNewRiskDescription('');
      setNewRiskVulnerabilityId('');
      setNewRiskConsequenceId('');
      setNewRiskAssetId('');
      setShowAddRiskForm(false);
      setMessage('Risk added successfully!');
    } else {
      setMessage('Error: Unable to add risk.');
    }
  };

  const calculateRiskScore = (vulnerabilityId, consequenceId) => {
    const vulnerability = vulnerabilities.find(v => v.id === vulnerabilityId);
    const consequence = consequences.find(c => c.id === consequenceId);
    return vulnerability && consequence ? vulnerability.original_score * consequence.original_score : 'N/A';
  };

  const calculateRiskPotentialScore = (vulnerabilityId, consequenceId) => {
    const vulnerability = vulnerabilities.find(v => v.id === vulnerabilityId);
    const consequence = consequences.find(c => c.id === consequenceId);
    return vulnerability && consequence ? vulnerability.potential_score * consequence.potential_score : 'N/A';
  };

  const calculateCurrentRiskScore = () => {
    return risks.reduce((total, risk) => total + (risk.current_score || 0), 0);
  };

  const getAssetName = (assetId) => {
    const asset = assets.find(a => a.id === assetId);
    return asset ? asset.name : 'Unknown Asset';
  };

  const handleOpenDialog = (riskId) => {
    setRiskToDelete(riskId);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setRiskToDelete(null);
  };

  const handleDeleteRisk = async () => {
    if (!riskToDelete) return;

    const { error: updateError } = await supabase
      .from('risks')
      .update({ deleted: true })
      .eq('id', riskToDelete);

    if (updateError) {
      setMessage(`Error: ${updateError.message}`);
      return;
    }

    const { error: riskOverTimeError } = await supabase
      .from('risk_over_time')
      .insert([{ risk_id: riskToDelete, risk_score: 0 }]);

    if (riskOverTimeError) {
      setMessage(`Error: ${riskOverTimeError.message}`);
      return;
    }

    setMessage('Risk deleted successfully!');
    setRisks(risks.filter(risk => risk.id !== riskToDelete));
    setOpenDialog(false);
    setRiskToDelete(null);
  };

  const toggleCommentSection = (riskId) => {
    setCommentSectionOpen((prev) => ({
      ...prev,
      [riskId]: !prev[riskId]
    }));
  };

  return (
    <Box sx={{ padding: 2, maxWidth: 1280, margin: '0 auto', mt: 10 }}>
      {riskAssessment ? (
        <>
          <Typography variant="h3" gutterBottom sx={{mb: 0}}>
            {riskAssessment.name}
          </Typography>
          {assessor && (
            <Typography variant="body2" paragraph>
              Assessor: {assessor}
            </Typography>
          )}
          <Typography variant="body1" paragraph>
            {riskAssessment.description}
          </Typography>
          <Typography variant="h4" gutterBottom sx={{mt: 2}}>
            Executive Summary
          </Typography>
          <ExecutiveSummary
            risks={risks}
            vulnerabilities={vulnerabilities}
            consequences={consequences}
            summary={summary}
            setSummary={setSummary}
            id={id}
            setMessage={setMessage}
            aiEnabled={aiEnabled} // Pass aiEnabled state
          />
          <VulnerabilityList
            vulnerabilities={vulnerabilities}
            riskAssessmentId={id}
            onVulnerabilityAdded={(newVulnerability) => setVulnerabilities([...vulnerabilities, newVulnerability])}
          />

          <ConsequenceList
            consequences={consequences}
            riskAssessmentId={id}
            onConsequenceAdded={(newConsequence) => setConsequences([...consequences, newConsequence])}
          />

          <Typography variant="h4" gutterBottom sx={{mt: 2}}>
            Risks
          </Typography>
          {risks.length > 0 ? (
            <Box component="ul" sx={{ padding: 0, listStyle: 'none' }}>
              {risks.map((risk) => (
                <Box key={risk.id} component="li" sx={{ marginBottom: 2 }}>
                  <Paper elevation={3} sx={{ p: 2, display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                      <Typography variant="h6">{risk.name}</Typography>
                      <Typography variant="body2" sx={{mb: 1}}>
                        Asset: {getAssetName(risk.asset_id)}
                      </Typography>
                      <Typography>{risk.description}</Typography>
                      <Typography>
                        Vulnerability: {vulnerabilities.find(v => v.id === risk.vulnerability_id)?.title || 'None'}
                      </Typography>
                      <Typography>
                        Consequence: {consequences.find(c => c.id === risk.consequence_id)?.title || 'None'}
                      </Typography>
                      
                      <Typography sx={{fontWeight: 'bold'}}>
                        Risk Score: {calculateRiskScore(risk.vulnerability_id, risk.consequence_id)}
                      </Typography>
                      <Typography sx={{fontWeight: 'bold'}}>
                        Potential Risk Score: {calculateRiskPotentialScore(risk.vulnerability_id, risk.consequence_id)}
                      </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column" alignItems="flex-end" sx={{ width: '230px' }}>
                      <Button
                        variant="outlined"
                        color="primary"
                        sx={{ mb: 1, width: '230px' }}
                      >
                        Edit Risk
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{ width: '230px', mb: 1 }}
                        onClick={() => toggleCommentSection(risk.id)}
                      >
                        {commentSectionOpen[risk.id] ? 'Hide Comments' : 'Show Comments'}
                      </Button>
                      <Button
                        variant="outlined"
                        sx={{
                          borderColor: 'red',
                          color: 'red',
                          width: '230px',
                          '&:hover': {
                            backgroundColor: 'red',
                            color: 'white',
                          },
                        }}
                        onClick={() => handleOpenDialog(risk.id)}
                      >
                        Delete Risk
                      </Button>
                      <Collapse in={commentSectionOpen[risk.id]} sx={{ width: '100%', mt: 2 }}>
                        <CommentList parentType="risk" parentId={risk.id} />
                      </Collapse>
                    </Box>
                  </Paper>
                </Box>
              ))}
            </Box>
          ) : (
            <Typography>No risks found. Click "Add Risk" to add one.</Typography>
          )}
          <Button onClick={() => setShowAddRiskForm(!showAddRiskForm)} variant="contained" color="secondary">
            {showAddRiskForm ? 'Cancel' : 'Add Risk'}
          </Button>
          {showAddRiskForm && (
            <Box component="form" onSubmit={handleAddRisk} sx={{ mt: 2 }}>
              <TextField
                fullWidth
                label="Risk Name"
                value={newRiskName}
                onChange={(e) => setNewRiskName(e.target.value)}
                required
                sx={{ mb: 2 }}
              />
              <TextField
                fullWidth
                label="Risk Description"
                value={newRiskDescription}
                onChange={(e) => setNewRiskDescription(e.target.value)}
                required
                sx={{ mb: 2 }}
                multiline
                rows={3}
              />
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="risk-vulnerability-label">Linked Vulnerability</InputLabel>
                <MuiSelect
                  labelId="risk-vulnerability-label"
                  value={newRiskVulnerabilityId}
                  onChange={(e) => setNewRiskVulnerabilityId(e.target.value)}
                  label="Linked Vulnerability"
                  required
                >
                  <MenuItem value="">
                    <em>Select Vulnerability</em>
                  </MenuItem>
                  {vulnerabilities.map((vulnerability) => (
                    <MenuItem key={vulnerability.id} value={vulnerability.id}>
                      {vulnerability.title}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="risk-consequence-label">Linked Consequence</InputLabel>
                <MuiSelect
                  labelId="risk-consequence-label"
                  value={newRiskConsequenceId}
                  onChange={(e) => setNewRiskConsequenceId(e.target.value)}
                  label="Linked Consequence"
                  required
                >
                  <MenuItem value="">
                    <em>Select Consequence</em>
                  </MenuItem>
                  {consequences.map((consequence) => (
                    <MenuItem key={consequence.id} value={consequence.id}>
                      {consequence.title}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
              <FormControl fullWidth sx={{ mb: 2 }}>
                <InputLabel id="risk-asset-label">Linked Asset</InputLabel>
                <MuiSelect
                  labelId="risk-asset-label"
                  value={newRiskAssetId}
                  onChange={(e) => setNewRiskAssetId(e.target.value)}
                  label="Linked Asset"
                  required
                >
                  <MenuItem value="">
                    <em>Select Asset</em>
                  </MenuItem>
                  {assets.map((asset) => (
                    <MenuItem key={asset.id} value={asset.id}>
                      {asset.name}
                    </MenuItem>
                  ))}
                </MuiSelect>
              </FormControl>
              <Button type="submit" variant="contained" color="primary">
                Add Risk
              </Button>
            </Box>
          )}
          {message && <Typography color="error">{message}</Typography>}

          <Dialog open={openDialog} onClose={handleCloseDialog}>
            <DialogTitle>Delete Risk</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this risk? This action cannot be undone.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseDialog} color="primary">
                Cancel
              </Button>
              <Button onClick={handleDeleteRisk} color="secondary">
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Typography>Loading...</Typography>
      )}
    </Box>
  );
};

export default RiskAssessmentDetails;
