import React, { useState, useEffect, useContext } from 'react';
import supabase from '../supabaseClient';
import { AuthContext } from '../contexts/AuthContext';
import { Box, Button, TextField, Typography, Paper } from '@mui/material';

const CommentList = ({ parentType, parentId }) => {
  const { user } = useContext(AuthContext);
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchComments = async () => {
      const { data: commentsData, error } = await supabase
        .from('comments')
        .select('id, content, created_at, user_id')
        .eq('parent_type', parentType)
        .eq('parent_id', parentId)
        .order('created_at', { ascending: true });

      if (error) {
        console.error('Error fetching comments:', error);
      } else {
        const userData = await supabase.from('user_info').select('id, name');
        const userMap = userData.data.reduce((acc, user) => {
          acc[user.id] = user.name;
          return acc;
        }, {});

        const commentsWithUser = commentsData.map(comment => ({
          ...comment,
          user_name: userMap[comment.user_id] || 'Unknown User',
        }));

        setComments(commentsWithUser);
      }
    };

    fetchComments();
  }, [parentType, parentId]);

  const handleAddComment = async (e) => {
    e.preventDefault();

    if (!newComment.trim()) {
      setMessage('Comment cannot be empty.');
      return;
    }

    const { data: comment, error } = await supabase
      .from('comments')
      .insert([{ content: newComment, parent_type: parentType, parent_id: parentId, user_id: user.id }])
      .select()
      .single();

    if (error) {
      setMessage(`Error: ${error.message}`);
      return;
    }

    setComments([...comments, { ...comment, user_name: user.name }]);
    setNewComment('');
    setMessage('');
  };

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Comments</Typography>
      <Box>
        {comments.map((comment) => (
          <Paper key={comment.id} sx={{ p: 2, mb: 2 }}>
            <Typography variant="body1">{comment.content}</Typography>
            <Typography variant="body2" color="textSecondary">
              By: {comment.user_name} on {new Date(comment.created_at).toLocaleString()}
            </Typography>
          </Paper>
        ))}
      </Box>
      <form onSubmit={handleAddComment}>
        <TextField
          fullWidth
          label="Add a comment"
          value={newComment}
          onChange={(e) => setNewComment(e.target.value)}
          sx={{ mb: 2 }}
          multiline
          rows={3}
        />
        <Button type="submit" variant="contained" color="primary">
          Add Comment
        </Button>
      </form>
      {message && <Typography color="error">{message}</Typography>}
    </Box>
  );
};

export default CommentList;
