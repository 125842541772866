import React, { useState } from 'react';
import { Box, Typography, Button, TextField, Grid, Container } from '@mui/material';
import supabase from '../supabaseClient';
import { track } from '@vercel/analytics';
import backgroundImage from '../assets/landingback.jpg'; // Adjust the path to your actual image
import '../App.css'; // Ensure this is imported to use the CSS classes

const BetaSignup = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleBetaSignup = async () => {
    if (email) {
      try {
        const { data, error } = await supabase.from('beta_signup').insert([{ email }]);
        if (error) throw error;
        setMessage(`Thank you for signing up for the beta, ${email}!`);
        setEmail('');

        // Track the beta signup event
        track('beta_signup', { email });
      } catch (error) {
        setMessage(`Error: ${error.message}`);
      }
    } else {
      setMessage('Please enter a valid email address.');
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      alignItems="center"
      justifyContent="flex-start"
      height="80vh"
      textAlign="left"
      p={3}
      className="beta-signup" // Use the CSS class
    >
      <Container maxWidth="lg">
        <Grid container>
          <Grid item xs={12} md={6}>
            <Box 
              display="flex" 
              flexDirection="column" 
              alignItems="flex-start" 
              className="beta-signup-box" // Use the CSS class
            >
              <Typography variant="h2" component="h1" gutterBottom>
                Manage Risk Effectively
              </Typography>
              <Typography variant="h5" component="p" gutterBottom>
                Evaluate risk, track mitigations and control your information security exposure
              </Typography>
              <Box mt={4} width="100%" maxWidth="400px">
                <TextField
                  fullWidth
                  label="Enter your email to register for beta access"
                  variant="outlined"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Button variant="contained" color="primary" size="large" onClick={handleBetaSignup} sx={{ marginTop: 2 }} fullWidth>
                  Register for Beta
                </Button>
                {message && (
                  <Typography variant="body1" color="secondary" mt={2}>
                    {message}
                  </Typography>
                )}
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default BetaSignup;
