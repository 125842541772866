// src/pages/Iso27001Process.js
import React, { useEffect, useState, useContext } from 'react';
import { Container, Typography, List, ListItem, ListItemText, Checkbox, FormControlLabel, Collapse, Card, CardContent, Divider } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import supabase from '../supabaseClient';
import { AuthContext } from '../contexts/AuthContext';

const Iso27001Process = () => {
  const { user } = useContext(AuthContext);
  const [organizationId, setOrganizationId] = useState(null);
  const [steps, setSteps] = useState([]);
  const [substeps, setSubsteps] = useState({});
  const [progress, setProgress] = useState({});
  const [substepProgress, setSubstepProgress] = useState({});
  const [openStep, setOpenStep] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchOrganizationId = async () => {
      if (user) {
        const { data: userInfo, error } = await supabase
          .from('user_info')
          .select('organization')
          .eq('id', user.id)
          .single();
        if (error) {
          console.error(error);
        } else {
          setOrganizationId(userInfo.organization);
        }
      }
    };

    fetchOrganizationId();
  }, [user]);

  useEffect(() => {
    const fetchSteps = async () => {
      const { data: stepsData, error: stepsError } = await supabase.from('iso27001_steps').select('*');
      if (stepsError) {
        console.error(stepsError);
      } else {
        setSteps(stepsData);
      }

      const { data: substepsData, error: substepsError } = await supabase.from('iso27001_substeps').select('*');
      if (substepsError) {
        console.error(substepsError);
      } else {
        const substepsMap = substepsData.reduce((acc, substep) => {
          if (!acc[substep.step_id]) {
            acc[substep.step_id] = [];
          }
          acc[substep.step_id].push(substep);
          return acc;
        }, {});
        setSubsteps(substepsMap);
      }

      if (organizationId) {
        const { data: progressData, error: progressError } = await supabase
          .from('iso27001_progress')
          .select('*')
          .eq('organization_id', organizationId);
        if (progressError) {
          console.error(progressError);
        } else {
          const progressMap = progressData.reduce((acc, item) => {
            if (item.substep_id) {
              if (!acc.substepProgress) {
                acc.substepProgress = {};
              }
              acc.substepProgress[item.substep_id] = item.completed;
            } else {
              acc[item.step_id] = item.completed;
            }
            return acc;
          }, { substepProgress: {} });
          setProgress(progressMap);
          setSubstepProgress(progressMap.substepProgress);
        }
      }

      setLoading(false);
    };

    if (organizationId) {
      fetchSteps();
    }
  }, [organizationId]);

  if (loading) {
    return (
      <Container sx={{ mt: 12 }}>
        <Typography variant="h4" gutterBottom>
          Loading...
        </Typography>
      </Container>
    );
  }

  if (!steps.length) {
    return (
      <Container sx={{ mt: 12 }}>
        <Typography variant="h4" gutterBottom>
          No steps found.
        </Typography>
      </Container>
    );
  }

  const handleToggle = async (stepId) => {
    const updatedProgress = { ...progress, [stepId]: !progress[stepId] };
    setProgress(updatedProgress);

    const { data, error } = await supabase
      .from('iso27001_progress')
      .upsert({
        organization_id: organizationId,
        step_id: stepId,
        substep_id: null,
        completed: updatedProgress[stepId],
      }, { onConflict: ['organization_id', 'step_id', 'substep_id'] });

    if (error) {
      console.error(error);
    }
  };

  const handleSubstepToggle = async (substepId) => {
    const updatedSubstepProgress = { ...substepProgress, [substepId]: !substepProgress[substepId] };
    setSubstepProgress(updatedSubstepProgress);

    const { data, error } = await supabase
      .from('iso27001_progress')
      .upsert({
        organization_id: organizationId,
        step_id: null,
        substep_id: substepId,
        completed: updatedSubstepProgress[substepId],
      }, { onConflict: ['organization_id', 'step_id', 'substep_id'] });

    if (error) {
      console.error(error);
    }
  };

  const handleStepClick = (stepId) => {
    setOpenStep(openStep === stepId ? null : stepId);
  };

  return (
    <Container sx={{ mt: 12 }}>
      <Typography variant="h4" gutterBottom>
        ISO 27001 and ISO 27005 Implementation Process
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Follow these steps to effectively implement and manage your Information Security Management System (ISMS).
      </Typography>
      <List>
        {steps.map((step) => (
          <React.Fragment key={step.id}>
            <Card sx={{ mb: 2 }}>
              <CardContent>
                <ListItem button onClick={() => handleStepClick(step.id)}>
                  <ListItemText
                    primary={
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={!!progress[step.id]}
                            onChange={(e) => {
                              e.stopPropagation();
                              handleToggle(step.id);
                            }}
                          />
                        }
                        label={<Typography variant="h6">{step.name}</Typography>}
                      />
                    }
                  />
                  {openStep === step.id ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
                <Collapse in={openStep === step.id} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {substeps[step.id] && substeps[step.id].map((substep) => (
                      <ListItem key={substep.id} sx={{ pl: 4 }}>
                        <ListItemText
                          primary={
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={!!substepProgress[substep.id]}
                                  onChange={(e) => {
                                    e.stopPropagation();
                                    handleSubstepToggle(substep.id);
                                  }}
                                />
                              }
                              label={substep.name}
                            />
                          }
                          secondary={substep.description}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              </CardContent>
            </Card>
            <Divider />
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default Iso27001Process;
