import React from 'react';
import { Container, Box, Typography, Grid, Paper, Button, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const pricingPlans = [
  {
    title: 'Free',
    price: 'Free',
    description: 'Perfect for individuals',
    features: ['1 User', 'Basic Features', 'Email Support'],
  },
  {
    title: 'Consultancy',
    price: '$500/year',
    description: 'Ideal for small teams',
    features: ['Up to 10 Users', 'Advanced Features', 'Priority Email Support'],
  },
  {
    title: 'Business',
    price: '$5000/year',
    description: 'Best for medium-sized teams',
    features: ['Up to 50 Users', 'All Features', 'Phone & Email Support'],
    highlight: true,
  },
  {
    title: 'Enterprise',
    price: 'Contact Us',
    description: 'Customized solutions',
    features: ['Unlimited Users', 'All Features', 'Dedicated Support', 'Custom Integrations'],
  },
];

const Pricing = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 10 }}>
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <Typography variant="h3" component="h1" gutterBottom>
          Pricing Plans
        </Typography>
        <Grid container spacing={4} justifyContent="center">
          {pricingPlans.map((plan) => (
            <Grid item xs={12} sm={6} md={3} key={plan.title}>
              <Paper
                elevation={plan.highlight ? 10 : 3}
                sx={{
                  padding: 3,
                  borderRadius: 2,
                  borderColor: plan.highlight ? 'primary.main' : 'grey.300',
                  borderWidth: plan.highlight ? 2 : 1,
                  borderStyle: 'solid',
                  height: '100%', // Make cards equal height
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <Box flexGrow={1} display="flex" flexDirection="column" justifyContent="space-between">
                  <Box>
                    <Typography variant="h5" component="h2" gutterBottom>
                      {plan.title}
                    </Typography>
                    <Typography variant="h6" component="p" color="primary" gutterBottom>
                      {plan.price}
                    </Typography>
                    <Typography variant="body1" component="p" gutterBottom>
                      {plan.description}
                    </Typography>
                  </Box>
                  <List sx={{ textAlign: 'left', flexGrow: 1 }}>
                    {plan.features.map((feature) => (
                      <ListItem key={feature}>
                        <ListItemIcon>
                          <CheckCircleIcon color="primary" />
                        </ListItemIcon>
                        <ListItemText primary={feature} />
                      </ListItem>
                    ))}
                  </List>
                </Box>
                <Button variant="contained" color={plan.highlight ? 'primary' : 'secondary'} sx={{ marginTop: 2 }}>
                  {plan.title === 'Enterprise' ? 'Contact Us' : 'Get Started'}
                </Button>
              </Paper>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Pricing;
