import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from '../supabaseClient';
import '../styles/CreateOrganization.css';

const CreateOrganization = () => {
  const navigate = useNavigate();
  const [organizationName, setOrganizationName] = useState('');
  const [inviteLink, setInviteLink] = useState('');
  const [error, setError] = useState('');
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const { data, error } = await supabase.auth.getUser();
      if (error || !data.user) {
        navigate('/login'); // Redirect to login if user is not authenticated
      } else {
        setUser(data.user);
      }
    };

    fetchUser();
  }, [navigate]);

  const handleCreateOrganization = async (e) => {
    e.preventDefault();

    const { data: organization, error: orgError } = await supabase
      .from('organizations')
      .insert([{ name: organizationName, invite_link: inviteLink }])
      .select()
      .single();

    if (orgError) {
      setError('Error creating organization');
      return;
    }

    const { error: userInfoError } = await supabase
      .from('user_info')
      .update({ organization: organization.id })
      .eq('id', user.id);

    if (userInfoError) {
      setError('Error linking organization to user');
    } else {
      navigate('/welcome');
    }
  };

  return (
    <div className="create-organization-container">
      <h2>Create Organization</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleCreateOrganization}>
        <div className="form-group">
          <label htmlFor="organization-name">Organization Name:</label>
          <input
            type="text"
            id="organization-name"
            value={organizationName}
            onChange={(e) => setOrganizationName(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="invite-link">Invite Link:</label>
          <input
            type="text"
            id="invite-link"
            value={inviteLink}
            onChange={(e) => setInviteLink(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Create Organization
        </button>
      </form>
    </div>
  );
};

export default CreateOrganization;
