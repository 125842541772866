import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import supabase from '../supabaseClient';
import { statusOptions } from '../constants/statusOptions';
import {
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  TextField,
  Collapse,
  IconButton,
  Box,
  Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

const MitigationList = ({
  parentId,
  type,
  newMitigation,
  users,
  onEditClick,
  onMitigationsChange,
}) => {
  const [mitigations, setMitigations] = useState([]);
  const [editMitigationId, setEditMitigationId] = useState(null);
  const [editMitigationTitle, setEditMitigationTitle] = useState('');
  const [editMitigationDescription, setEditMitigationDescription] = useState('');
  const [editMitigationScore, setEditMitigationScore] = useState(1);
  const [editResponsibleUser, setEditResponsibleUser] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [showStatusDropdown, setShowStatusDropdown] = useState(null);
  const [isCollapsed, setIsCollapsed] = useState(true);

  useEffect(() => {
    const fetchMitigations = async () => {
      let query = supabase
        .from('mitigations')
        .select('id, title, description, score, responsible_user, status');

      if (type === 'vulnerability') {
        query = query.eq('vulnerability_id', parentId);
      } else if (type === 'consequence') {
        query = query.eq('consequence_id', parentId);
      }

      const { data, error } = await query;

      if (error) {
        console.error('Error fetching mitigations:', error);
      } else {
        setMitigations(data);
        onMitigationsChange(data.length > 0); // Notify parent about the presence of mitigations
      }
    };

    fetchMitigations();
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (
      newMitigation &&
      (newMitigation.vulnerability_id === parentId ||
        newMitigation.consequence_id === parentId)
    ) {
      setMitigations((prevMitigations) => {
        const updatedMitigations = [...prevMitigations, newMitigation];
        onMitigationsChange(updatedMitigations.length > 0); // Notify parent about the presence of mitigations
        return updatedMitigations;
      });
      adjustPotentialScore(newMitigation.score); // Adjust potential score when new mitigation is added
    }
  }, [newMitigation]); // Depend on newMitigation to update the list

  const handleEditMitigation = (mitigation) => {
    setEditMitigationId(mitigation.id);
    setEditMitigationTitle(mitigation.title);
    setEditMitigationDescription(mitigation.description);
    setEditMitigationScore(mitigation.score);
    setEditResponsibleUser(
      users.find((user) => user.value === mitigation.responsible_user) || null
    );
  };

  const handleUpdateMitigation = async (e, mitigationId) => {
    e.preventDefault();

    const { data, error } = await supabase
      .from('mitigations')
      .update({
        title: editMitigationTitle,
        description: editMitigationDescription,
        score: editMitigationScore,
        responsible_user: editResponsibleUser.value,
      })
      .eq('id', mitigationId)
      .select();

    if (error) {
      console.error('Error updating mitigation:', error);
    } else {
      setMitigations((prevMitigations) =>
        prevMitigations.map((mitigation) =>
          mitigation.id === mitigationId ? data[0] : mitigation
        )
      );
      setEditMitigationId(null);
    }
  };

  const handleDeleteMitigation = async (mitigationId) => {
    const { error } = await supabase.from('mitigations').delete().eq('id', mitigationId);

    if (error) {
      console.error('Error deleting mitigation:', error);
    } else {
      setMitigations((prevMitigations) => {
        const updatedMitigations = prevMitigations.filter(
          (mitigation) => mitigation.id !== mitigationId
        );
        onMitigationsChange(updatedMitigations.length > 0);
        return updatedMitigations;
      });
    }
  };

  const handleStatusChange = (mitigation) => {
    setShowStatusDropdown(mitigation.id);
    setSelectedStatus(mitigation.status);
  };

  const updateStatus = async (mitigation) => {
    const { data, error } = await supabase
      .from('mitigations')
      .update({ status: selectedStatus })
      .eq('id', mitigation.id)
      .select();

    if (error) {
      console.error('Error updating status:', error);
      return;
    }

    setMitigations((prevMitigations) =>
      prevMitigations.map((m) => (m.id === mitigation.id ? data[0] : m))
    );
    setShowStatusDropdown(null);

    if (selectedStatus === 'Completed') {
      await adjustParentScore(mitigation.score, mitigation);
    }
  };

  const adjustPotentialScore = async (mitigationScore) => {
    const parentTable = type === 'vulnerability' ? 'vulnerabilities' : 'consequences';
    const { data, error } = await supabase
      .from(parentTable)
      .select('original_score, potential_score')
      .eq('id', parentId)
      .single();

    if (error) {
      console.error(`Error fetching ${type} score:`, error);
      return;
    }

    const newPotentialScore = data.original_score - mitigationScore;

    const { error: updateError } = await supabase
      .from(parentTable)
      .update({ potential_score: newPotentialScore })
      .eq('id', parentId);

    if (updateError) {
      console.error(`Error updating ${type} score:`, updateError);
      return;
    }

    // Fetch the risks related to this vulnerability or consequence
    const riskField = type === 'vulnerability' ? 'vulnerability_id' : 'consequence_id';
    const { data: risks, error: risksError } = await supabase
      .from('risks')
      .select('id, vulnerability_id, consequence_id')
      .eq(riskField, parentId);

    if (risksError) {
      console.error('Error fetching risks:', risksError);
      return;
    }

    // Update the risk potential scores
    for (const risk of risks) {
      const updatedRiskPotentialScore = await calculateRiskPotentialScore(risk.vulnerability_id, risk.consequence_id);

      const { error: riskUpdateError } = await supabase
        .from('risks')
        .update({ potential_score: updatedRiskPotentialScore })
        .eq('id', risk.id);

      if (riskUpdateError) {
        console.error('Error updating risk potential score:', riskUpdateError);
      }

      // Update the risk_over_time table
      const { error: riskOverTimeError } = await supabase
        .from('risk_over_time')
        .insert([{ risk_id: risk.id, risk_score: updatedRiskPotentialScore }]);

      if (riskOverTimeError) {
        console.error('Error updating risk over time:', riskOverTimeError);
      }
    }
  };

  const adjustParentScore = async (mitigationScore, mitigation) => {
    const parentTable = type === 'vulnerability' ? 'vulnerabilities' : 'consequences';
    const { data, error } = await supabase
      .from(parentTable)
      .select('score')
      .eq('id', parentId)
      .single();

    if (error) {
      console.error(`Error fetching ${type} score:`, error);
      return;
    }

    const newScore = data.score - mitigationScore;

    const { error: updateError } = await supabase
      .from(parentTable)
      .update({ score: newScore })
      .eq('id', parentId);

    if (updateError) {
      console.error(`Error updating ${type} score:`, updateError);
      return;
    }

    // Fetch the risks related to this vulnerability or consequence
    const riskField = type === 'vulnerability' ? 'vulnerability_id' : 'consequence_id';
    const { data: risks, error: risksError } = await supabase
      .from('risks')
      .select('id, vulnerability_id, consequence_id')
      .eq(riskField, parentId);

    if (risksError) {
      console.error('Error fetching risks:', risksError);
      return;
    }

    // Update the risk scores and insert into risk_over_time
    for (const risk of risks) {
      const updatedRiskScore = await calculateRiskScore(risk.vulnerability_id, risk.consequence_id);

      // Update the risk_over_time table
      const { error: riskOverTimeError } = await supabase
        .from('risk_over_time')
        .insert([{ risk_id: risk.id, risk_score: updatedRiskScore }]);

      if (riskOverTimeError) {
        console.error('Error updating risk over time:', riskOverTimeError);
      }
    }
  };

  const calculateRiskPotentialScore = async (vulnerabilityId, consequenceId) => {
    // Fetch vulnerability and consequence potential scores from the database
    const { data: vulnerability, error: vulnerabilityError } = await supabase
      .from('vulnerabilities')
      .select('potential_score')
      .eq('id', vulnerabilityId)
      .single();

    if (vulnerabilityError) {
      console.error('Error fetching vulnerability:', vulnerabilityError);
      return 'N/A';
    }

    const { data: consequence, error: consequenceError } = await supabase
      .from('consequences')
      .select('potential_score')
      .eq('id', consequenceId)
      .single();

    if (consequenceError) {
      console.error('Error fetching consequence:', consequenceError);
      return 'N/A';
    }

    return vulnerability.potential_score * consequence.potential_score;
  };

  const calculateRiskScore = async (vulnerabilityId, consequenceId) => {
    // Fetch vulnerability and consequence information from the database
    const { data: vulnerability, error: vulnerabilityError } = await supabase
      .from('vulnerabilities')
      .select('score')
      .eq('id', vulnerabilityId)
      .single();

    if (vulnerabilityError) {
      console.error('Error fetching vulnerability:', vulnerabilityError);
      return 'N/A';
    }

    const { data: consequence, error: consequenceError } = await supabase
      .from('consequences')
      .select('score')
      .eq('id', consequenceId)
      .single();

    if (consequenceError) {
      console.error('Error fetching consequence:', consequenceError);
      return 'N/A';
    }

    return vulnerability.score * consequence.score;
  };

  const handleSelectChange = (selectedOption) => {
    setSelectedStatus(selectedOption.value);
  };

  const handleCancelStatusChange = () => {
    setShowStatusDropdown(null);
    setSelectedStatus(null);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  return (
    <Box mt={8}>
      <Box display="flex" alignItems="center" onClick={toggleCollapse} sx={{ cursor: 'pointer' }}>
        <Typography variant="h6" component="div">
          Mitigation
        </Typography>
        <IconButton>
          {isCollapsed ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </IconButton>
      </Box>
      <Collapse in={!isCollapsed}>
        <Grid container spacing={2}>
          {mitigations.length > 0 ? (
            mitigations.map((mitigation) => (
              <Grid item xs={12} key={mitigation.id}>
                <Card>
                  <CardContent>
                    <Typography variant="h5" component="div">
                      {mitigation.title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {mitigation.description}
                    </Typography>
                    <Typography variant="body2">
                      Score: {mitigation.score}
                    </Typography>
                    <Typography variant="body2">
                      Responsible User:{' '}
                      {users.find((user) => user.value === mitigation.responsible_user)?.label ||
                        'None'}
                    </Typography>
                    <Typography variant="body2">Status: {mitigation.status}</Typography>
                  </CardContent>
                  <CardActions>
                    {mitigation.status === 'New' ? (
                      <>
                        {editMitigationId === mitigation.id ? (
                          <form onSubmit={(e) => handleUpdateMitigation(e, mitigation.id)}>
                            <TextField
                              label="Mitigation Title"
                              value={editMitigationTitle}
                              onChange={(e) => setEditMitigationTitle(e.target.value)}
                              required
                              fullWidth
                              margin="normal"
                            />
                            <TextField
                              label="Mitigation Description"
                              value={editMitigationDescription}
                              onChange={(e) => setEditMitigationDescription(e.target.value)}
                              required
                              fullWidth
                              margin="normal"
                              multiline
                            />
                            <TextField
                              select
                              label="Mitigation Score"
                              value={editMitigationScore}
                              onChange={(e) => setEditMitigationScore(parseInt(e.target.value))}
                              required
                              fullWidth
                              margin="normal"
                              SelectProps={{
                                native: true,
                              }}
                            >
                              {[1, 2, 3, 4, 5].map((score) => (
                                <option key={score} value={score}>
                                  {score}
                                </option>
                              ))}
                            </TextField>
                            <Select
                              options={users}
                              value={editResponsibleUser}
                              onChange={setEditResponsibleUser}
                              isClearable
                              isSearchable
                              placeholder="Select user"
                              required
                            />
                            <Button type="submit" variant="contained" color="primary" fullWidth>
                              Update Mitigation
                            </Button>
                          </form>
                        ) : (
                          <Button
                            onClick={() => handleEditMitigation(mitigation)}
                            variant="contained"
                            color="primary"
                          >
                            Edit Mitigation
                          </Button>
                        )}
                      </>
                    ) : (
                      mitigation.status !== 'Completed' && (
                        <Button
                          onClick={() => handleDeleteMitigation(mitigation.id)}
                          variant="contained"
                          color="secondary"
                        >
                          Delete Mitigation
                        </Button>
                      )
                    )}
                    {showStatusDropdown === mitigation.id ? (
                      <Box display="flex" flexDirection="column">
                        <Select
                          options={statusOptions}
                          value={{ value: selectedStatus, label: selectedStatus }}
                          onChange={handleSelectChange}
                        />
                        <Box display="flex" justifyContent="space-between" mt={1}>
                          <Button
                            onClick={() => updateStatus(mitigation)}
                            variant="contained"
                            color="primary"
                          >
                            Save
                          </Button>
                          <Button
                            onClick={handleCancelStatusChange}
                            variant="contained"
                            color="secondary"
                          >
                            Cancel
                          </Button>
                        </Box>
                      </Box>
                    ) : (
                      mitigation.status !== 'Completed' && (
                        <Button
                          onClick={() => handleStatusChange(mitigation)}
                          variant="contained"
                          color="primary"
                        >
                          Change Status
                        </Button>
                      )
                    )}
                  </CardActions>
                </Card>
              </Grid>
            ))
          ) : (
            <Typography variant="body1">No mitigations found.</Typography>
          )}
        </Grid>
      </Collapse>
    </Box>
  );
};

export default MitigationList;
